import React from 'react'
import actionTypes from '../actionTypes'
import {
  loadFail,
  loadSuccess,
  startFetchingAction,
  startFetchingCartAction, stopFetchingAction,
  stopFetchingCartAction,
  startFetchingCouponAction, stopFetchingCouponAction,
  startFetchingExchangeRateAction, stopFetchingExchangeRateAction, redirectPath
} from './commonAction'
import { axiosProviderCart, axiosProviderOrder, axiosProviderVoucher, axiosProviderPromotion } from './api/axiosProvider'
import { getAuthCookies } from '../utils/auth'
import Coupon from '../model/Coupon'
import { modalUtils, LocalStorage } from './../utils'
import { message } from 'antd'
import { FormattedMessage } from 'react-intl.macro'
import moment from 'moment'
import { get, cloneDeep } from 'lodash'
import { objectExtensions } from '../extensions'
import { enumType, LOCAL_STORAGE_KEY, routes } from '../constants'
import { COUPON } from '../constants/enumType';
import Cart from '../model/Cart'
import loggingAction from './loggingAction'

export default {
  clearAddToCartNotice() {
    return dispatch => {
      dispatch(
        loadSuccess(actionTypes.CLEAR_ADD_TO_CART_NOTICE)
      )
    }
  },

  clearCart() {
    return dispatch => {
      dispatch(
        this.updateCoupon(undefined)
      )
      return dispatch(
        loadSuccess(actionTypes.CLEAR_CART_SUCCESS)
      )
    }
  },

  addToCart(data, productVariantId, redirectIfPassAPI = false, intl) {
    return async (dispatch) => {
      let returnValue;
      let addCartSuccessMessage;
      dispatch(startFetchingAction())
      try {
        const response = await axiosProviderCart.post(`/cart/addtocart`, data)
        returnValue = cloneDeep(response);
        if (response.status === 200 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_ADD_TO_CART,
              {
                cart: response.data.data,
                productVariantId: productVariantId
              }
            )
          )
          dispatch(this.countCartItem())
          if (intl) {
            addCartSuccessMessage = intl.formatMessage({
              id: 'AddToCartSuccessfully',
              defaultMessage: 'Thêm vào giỏ hàng thành công'
            })
          } else {
            addCartSuccessMessage = <FormattedMessage
              id="AddToCartSuccessfully"
              defaultMessage='Thêm vào giỏ hàng thành công'
            />
          }
          message.success(addCartSuccessMessage)
          if (redirectIfPassAPI) {
            const checkedItem = {
              productVariantId: data.id || productVariantId,
              usingDate: data.usingDate,
              tourId: data.tourId
            }
            // LocalStorage.set(LOCAL_STORAGE_KEY.CART_CHECKED_ITEM, checkedItem)
            LocalStorage.set(LOCAL_STORAGE_KEY.CART_CHECKED_ITEM, checkedItem)
            // setTimeout(() => redirectPageCallback(routes.CART), 300)
            setTimeout(() => dispatch(redirectPath(routes.CART)), 300)
          }
        } else {
          dispatch(loadFail(response.errors[0]))
        }
      } catch (error) {
        const errorCore = get(error, 'response.data.errors[0].code');
        if (errorCore === enumType.apiErrorCode.CART_WAS_PAID) {
          data.cartCode = undefined;
          dispatch(this.clearCart())
          dispatch(this.addToCart(data, productVariantId))
        } else {
          dispatch(loadFail(error.response))
        }
      }
      dispatch(stopFetchingAction())
      return returnValue;
    }
  },

  addToCartMulti(data, productVariantIds, redirectIfPassAPI = false, intl) {
    return async (dispatch) => {
      let returnValue;
      let addCartSuccessMessage;
      dispatch(startFetchingAction())
      try {
        const response = await axiosProviderCart.post(`/cart/addtocarts`, data)
        returnValue = cloneDeep(response);
        if (response.status === 200 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_ADD_TO_CART,
              {
                cart: response.data.data,
                productVariantId: productVariantIds
              }
            )
          )
          dispatch(this.countCartItem())
          if (intl) {
            addCartSuccessMessage = intl.formatMessage({
              id: 'AddToCartSuccessfully',
              defaultMessage: 'Thêm vào giỏ hàng thành công'
            })
          } else {
            addCartSuccessMessage = <FormattedMessage
              id="AddToCartSuccessfully"
              defaultMessage='Thêm vào giỏ hàng thành công'
            />
          }
          message.success(addCartSuccessMessage)
          if (redirectIfPassAPI) {
            const checkedItem = (data.cartRequests || []).map((item, index) => ({
              productVariantId: productVariantIds[index] && item.skuType !== 'sku' || item.id || item.productVariantId,
              usingDate: item.usingDate,
              tourId: item.tourId
            }))
            // LocalStorage.set(LOCAL_STORAGE_KEY.CART_CHECKED_ITEM, checkedItem)
            LocalStorage.set(LOCAL_STORAGE_KEY.CART_CHECKED_ITEM, checkedItem)
            // setTimeout(() => redirectPageCallback(routes.CART), 300)
            setTimeout(() => dispatch(redirectPath(routes.CART)), 300)
          }
        } else {
          dispatch(loadFail(response.errors[0]))
        }
      } catch (error) {
        const errorCore = get(error, 'response.data.errors[0].code');
        if (errorCore === enumType.apiErrorCode.CART_WAS_PAID) {
          data.cartCode = undefined;
          dispatch(this.clearCart())
          dispatch(this.addToCartMulti(data, productVariantIds))
        } else {
          dispatch(loadFail(error.response))
        }
      }
      dispatch(stopFetchingAction())
      return returnValue;
    }
  },

  loadCurrentCart() {
    return async (dispatch, getState) => {
      dispatch(startFetchingCartAction())
      try {
        const { cart = {} } = getState()
        const cartCode = cart && cart.currentCart ? cart.currentCart.cartCode : null
        if (cartCode) {
          // const response = await axiosProviderCart.get(`/cart/${cartCode}`)
          const response = await axiosProviderCart.get(`/cart/${cartCode}`)

          if (response.status === 200 && !response.errors) {
            dispatch(
              loadSuccess(
                actionTypes.FETCHING_CART_DETAIL_SUCCESS,
                response.data.data
              )
            )
            dispatch(this.countCartItem())
          } else {
            dispatch(loadFail(response.errors[0]))
          }
        }
      } catch (error) {
        const errorCore = get(error, 'response.data.errors[0].code');
        if (errorCore === enumType.apiErrorCode.CART_WAS_PAID) {
          dispatch(this.clearCart())
        } else {
          dispatch(loadFail(error.response))
        }
      }
      dispatch(stopFetchingCartAction())
    }
  },

  countCartItem() {
    return async (dispatch, getState) => {
      dispatch(startFetchingCartAction())
      try {
        const { cart = {} } = getState()
        const cartCode = cart && cart.currentCart ? cart.currentCart.cartCode : null

        if (cartCode) {
          const response = await axiosProviderCart.get(`/cart/count/${cartCode}`)

          if (response.status === 200 && !response.errors) {
            dispatch(
              loadSuccess(
                actionTypes.FETCHING_COUNT_CART_ITEM,
                response.data.data
              )
            )
          } else {
            dispatch(loadFail(response.errors[0]))
          }
        }
      } catch (error) {
        const errorCore = get(error, 'response.data.errors[0].code');
        if (errorCore === enumType.apiErrorCode.CART_WAS_PAID) {
          dispatch(this.clearCart())
        } else {
          dispatch(loadFail(error.response))
        }
      }
      dispatch(stopFetchingCartAction())
    }
  },

  deleteCartItem(id, usingDate = undefined, sessionId = 0) {
    return async (dispatch, getState) => {
      dispatch(startFetchingAction())
      try {
        const { cart = {} } = getState()
        const cartCode = cart && cart.currentCart ? cart.currentCart.cartCode : null;
        const options = usingDate ? { params: { usingDate, sessionId } } : undefined;

        const response = await axiosProviderCart.delete(`/cart/${cartCode}/item/${id}`, options)

        if (response.status === 204 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.DELETE_CART_ITEM_SUCCESS,
              { id, usingDate, sessionId }
            )
          )
          dispatch(this.countCartItem())
          if (cart && cart.coupon && cart.coupon.code && !cart.coupon.error) {
            dispatch(this.getCoupon(cart.coupon.code))
          }
        } else {
          dispatch(loadFail(response.errors[0]))
        }

      } catch (error) {
        dispatch(loadFail(error.response))
      }
      dispatch(stopFetchingAction())
    }
  },

  updateQuantityCartItem(data, callbacks) {
    return async (dispatch, getState) => {
      dispatch(startFetchingAction())
      try {
        const { cart = {} } = getState()
        const response = await axiosProviderCart.put(`/cart`, data)

        if (response.status === 200 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.UPDATE_CART_ITEM_SUCCESS,
              data
            )
          )
          dispatch(this.countCartItem())
          callbacks?.onSuccess && callbacks?.onSuccess();
          if (cart && cart.coupon && cart.coupon.code && !cart.coupon.error) {
            dispatch(this.getCoupon(cart.coupon.code))
          }
        } else {
          dispatch(loadFail(response.errors[0]))
        }
      } catch (error) {
        const errorCode = get(error, 'response.data.errors[0].code');
        let isReload = errorCode === enumType.apiErrorCode.INVALID_QUANTITY && callbacks && !callbacks?.isPlus;
        dispatch(loadFail(error.response, undefined, undefined, { isReload }))
      }
      dispatch(stopFetchingAction())
    }
  },

  checkoutCart(data, callback) {
    return async (dispatch, getState) => {
      if (getState().fetching.isLoading) return;
      dispatch(startFetchingAction())
      try {
        const authInfo = getAuthCookies()
        let config
        if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
          config = {
            headers: {
              Authorization: `Bearer ${authInfo.auth.accessToken}`,
            }
          }
        }

        const response = await axiosProviderCart.post(`/cart/checkout`, data, config)

        if (response.status === 200 && !response.errors) {
            dispatch(
              loadSuccess(
                actionTypes.CHECKOUT_CART_SUCCESS,
                null,
                null,
                null,
                [
                  window.open(response.data.data.paymentUrl, '_self')
                ]
              )
            )
          // LocalStorage.remove(LOCAL_STORAGE_KEY.PAYMENT_METHOD)
        } else {
          dispatch(loadFail(response.errors[0]))
        }
      } catch (error) {
        let isReload = false;
        const errorCore = get(error, 'response.data.errors[0].code');
        const redirectPath = 
          [enumType.apiErrorCode.GET_EXCHANGE_POINT_FAIL, enumType.apiErrorCode.ORDER_FAILED].includes(errorCore)
            ? '/order'
            : undefined;

        if (redirectPath === '/order') {
          dispatch(loggingAction.pushLogGA4({
            actionName: 'checkout',
            message: `checkoutCart - ${JSON.stringify(error)}`
          }))
        }

        if (errorCore === enumType.apiErrorCode.ITEM_DISABLE_SELL || errorCore === enumType.apiErrorCode.INVALID_QUANTITY) {
          isReload = true;
        }
        // if (errorCore === enumType.apiErrorCode.CART_WAS_PAID) {
        //   dispatch(this.clearCart())
        // }
          dispatch(loadFail(error.response, redirectPath, undefined, { isReload: isReload }))
      }
      dispatch(stopFetchingAction())
      if (callback) callback()
    }
  },

  getCoupon(couponCode, productVariantIds = undefined) {
    return async (dispatch, getState) => {
      dispatch(startFetchingCouponAction())
      const authInfo = getAuthCookies();
      const { cart = {} } = getState();

      let config
      if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
        config = {
          headers: {
            Authorization: `Bearer ${authInfo.auth.accessToken}`,
          }
        }
      }
      // debugger
      let dataCart = !productVariantIds ? cart.cart : productVariantIds
      // console.log('===== getCoupon', productVariantIds, dataCart)

      // if(!productVariantIds) {
      productVariantIds = objectExtensions.getCartProductIds(dataCart);
      // }

      // const data = {
      //   couponCode,
      //   voucherProductVariantIds: productVariantIds.vouchers,
      //   tourProductVariantIds: productVariantIds.tours,
      //   vinWonderProductVariantIds: productVariantIds.vinWonders,
      // }

      await dispatch(this.getPromotion(couponCode, productVariantIds, config))

      // if (dataPromotion.isValid) {
      //   return
      // }

      // try {
      //   const response = await axiosProviderOrder.post(`/frontend/order/checkcoupon`, data, config)

      //   if (response.status === 200 && !response.errors) {
      //     dispatch(loadSuccess(
      //       actionTypes.UPDATE_COUPON,
      //       new Coupon({ ...response.data.data, code: couponCode, productVariantIds })
      //     ))
      //   } else {
      //     const error = modalUtils.getApiMessageError(response.errors[0].code)
      //     dispatch(loadSuccess(
      //       actionTypes.UPDATE_COUPON,
      //       new Coupon({ code: couponCode, productVariantIds, error })
      //     ))
      //   }
      // } catch (error) {
      //   const errorModel = modalUtils.getApiMessageError(error.response)
      //   console.log(errorModel)
      //   dispatch(loadSuccess(
      //     actionTypes.UPDATE_COUPON,
      //     errorModel && new Coupon({ code: couponCode, productVariantIds, error: errorModel })
      //   ))
      // }
      dispatch(stopFetchingCouponAction())
    }
  },

  updateCoupon(coupon) {
    return async (dispatch) => {
      try {
        dispatch(loadSuccess(
          actionTypes.UPDATE_COUPON,
          coupon
        ))
      } catch (error) {
        dispatch(loadFail(error.response))
      }
    }
  },
  getExchangeRate() {
    return async (dispatch) => {
      dispatch(startFetchingExchangeRateAction())
      try {
        const response = await axiosProviderVoucher.get(`/frontend/exchangerate`)

        if (response.status === 200 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_EXCHANGERATE_SUCCESS,
              response.data.data
            )
          )
        } else {
          dispatch(loadFail(response.errors[0]))
        }
      } catch (error) {
        dispatch(loadFail(error.response))
      }
      dispatch(stopFetchingExchangeRateAction())
    }
  },

  getPromotion(promotionCode, products, config) {
    return async (dispatch) => {
      const data = {
        promotionCode,
        useDate: '',
        products: [
          // {
          //   productType: 'VOUCHER',
          //   productValue: products.vouchers,
          // },
          {
            productType: 'VINWONDER',
            productValue: products.vinWondersPromotion,
          },
          {
            productType: 'TOUR',
            productValue: products.toursPromotion,
          }
        ],
        channel: 'VPT'
      }
      const dataPromotion = await axiosProviderPromotion.post(`/promotion/check-code`, data, config)
      if (dataPromotion.data.isValid) {
        dataPromotion.data.discount.discountType = COUPON.PROMOTION_DISCONT_TYPE[dataPromotion.data.discount.discountType]
        dispatch(loadSuccess(
          actionTypes.UPDATE_COUPON,
          new Coupon({
            ...dataPromotion.data.discount,
            code: promotionCode,
            products,
            isValid: dataPromotion.data.isValid,
            isPromotion: true,
          })
        ))
      } else {
        const textError = ["COUPON_NOT_FOUND", "INVALID_SCHEDULE"]
        const dataError = {
          data: {
            errors: [{
              code: textError.includes(dataPromotion?.data?.errorCode)
                ? "COUPON_NOT_FOUND"
                : dataPromotion?.data?.errorCode || "COUPON_NOT_FOUND",
              message: dataPromotion?.data?.errorMessage || "Cannot find coupon "
            }]
          }
        }
        const errorCodeLogin = {
          data: {
            errors: [{
              code: dataPromotion?.data?.errorCode || "PROMOTION_SIGNIN_REQUIRED",
              message: dataPromotion?.data?.errorMessage || "Sign in required to use promotion"
            }]
          }
        }
        const errorModel = modalUtils.getApiMessageError(dataError, errorCodeLogin, { promotionCode })
        dispatch(loadSuccess(
          actionTypes.UPDATE_COUPON,
          errorModel && new Coupon({ code: promotionCode, products, error: errorModel })
        ))
      }
      dispatch(stopFetchingCouponAction())
      return dataPromotion.data
    }
  },
}
