import { axiosProviderVoucher } from "./api/axiosProvider";

export const pushLogGA4 = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderVoucher.post(
        `/frontend/logging`,
        payload
      );
    } catch (errors) {
      console.log("push log error", errors);
    }
  };
};
export default {
  pushLogGA4,
};
