import { convertLinkLocale } from '@utils/common'
import Router from '@utils/router'
import { show } from 'redux-modal'
import { enumType, LOCAL_STORAGE_KEY } from 'src/constants'
import actionTypes from '../actionTypes'

import ActionType from '../actionTypes'
import { UNDEFINED_ERROR } from '../constants/errorCode'
//Utils
import { LocalStorage } from '../utils'
import { axiosProviderCms, createAxiosProvider } from './api/axiosProvider'

/**
 * start fetching action
 */
export const startFetchingAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING
    })
  }
}
/**
 * 
 * @returns 
 */
export const startFetchingVietJetAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_INTERNATIONAL_NAME
    })
  }
}
/**
 * 
 * @returns 
 */
export const stopFetchingVietJetAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_INTERNATIONAL_NAME
    })
  }
}
/**
 * stop fetching action
 */
export const stopFetchingAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING
    })
  }
}

//hold
export const startFetchingHoldAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_HOLD
    })
  }
}

export const stopFetchingHoldAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_HOLD
    })
  }
}

export const startFetchingAuthAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_AUTH
    })
  }
}

export const startFetchingAuthLoginVptAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_AUTH_LOGIN_VPT
    })
  }
}

export const stopFetchingAuthLoginVptAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_AUTH_LOGIN_VPT
    })
  }
}

export const stopFetchingAuthAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_AUTH
    })
  }
}

export const startFetchingRegister = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_REGISTER
    })
  }
}

export const stopFetchingRegister = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_REGISTER
    })
  }
}

export const startFetchingUpdateUser = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_UPDATE_USER
    })
  }
}

export const stopFetchingUpdateUser = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_UPDATE_USER
    })
  }
}

export const startFetchingUploadAvata = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_UPLOAD_AVATA
    })
  }
}

export const stopFetchingUploadAvata = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_UPLOAD_AVATA
    })
  }
}

export const startFetchingForgotPwd = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_FORGOT_PWD
    })
  }
}

export const stopFetchingForgotPwd = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_FORGOT_PWD
    })
  }
}

export const startFetchingSendOTP = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_SEND_OTP
    })
  }
}

export const stopFetchingSendOTP = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_SEND_OTP
    })
  }
}

export const startFetchingCheckUser = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_CHECK_USER
    })
  }
}

export const stopFetchingCheckUser = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_CHECK_USER
    })
  }
}

export const startFetchingLinkAccount = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_LINK_ACCOUNT
    })
  }
}

export const stopFetchingLinkAccount = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_LINK_ACCOUNT
    })
  }
}

export const startFetchingRemoveOldPhone = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_REMOVE_OLD_PHONE
    })
  }
}

export const stopFetchingRemoveOldPhone = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_REMOVE_OLD_PHONE
    })
  }
}

export const startFetchingFlightAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_FLIGHT
    })
  }
}

export const stopFetchingFlightAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_FLIGHT
    })
  }
}

export const startFetchingHotelAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_HOTEL
    })
  }
}

export const stopFetchingHotelAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_HOTEL
    })
  }
}

export const startFetchingHotelRoomAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_HOTEL_ROOM
    })
  }
}

export const stopFetchingHotelRoomAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_HOTEL_ROOM
    })
  }
}

export const startFetchingVoucherAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_VOUCHER
    })
  }
}

export const stopFetchingVoucherAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_VOUCHER
    })
  }
}

export const startFetchingTourAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_TOUR
    })
  }
}

export const stopFetchingTourAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_TOUR
    })
  }
}

export const startFetchingTourTicketAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_TOUR_TICKET
    })
  }
}

export const stopFetchingTourTicketAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_TOUR_TICKET
    })
  }
}

export const startFetchingTourLineAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_TOUR_LINE
    })
  }
}

export const stopFetchingTourLineAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_TOUR_LINE
    })
  }
}

export const startFetchingCartAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_CART
    })
  }
}


export const stopFetchingCartAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_CART
    })
  }
}

export const startFetchingCouponAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_COUPON
    })
  }
}

export const stopFetchingCouponAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_COUPON
    })
  }
}

export const startFetchingOrderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_ORDER
    })
  }
}

export const stopFetchingOrderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_ORDER
    })
  }
}
export const startFetchingListOrderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_LIST_ORDER
    })
  }
}

export const stopFetchingListOrderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_LIST_ORDER
    })
  }
}

export const startFetchingVinWonderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_VINWONDER
    })
  }
}

export const stopFetchingVinWonderAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_VINWONDER
    })
  }
}

export const startFetchingExchangeRateAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_EXCHANGERATE
    })
  }
}

export const stopFetchingExchangeRateAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_EXCHANGERATE
    })
  }
}

/**
 * clear success action
 * @param {*} actionType
 */
export const clearSuccess = actionType => {
  return dispatch => {
    dispatch({
      type: actionType
    })
  }
}

/**
 * load data
 * @param {*} actionType
 * @param {*} data
 */
export const loadData = (actionType, data) => {
  return dispatch => {
    dispatch({
      type: actionType,
      payload: { data: data }
    })
  }
}

/**
 * redirect action
 * @param {*} path
 */
export const redirectPath = (path, asPath, options) => {
  const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
  const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
  Router.push(
    convertLinkLocale(language.data, path, currency.data), 
    asPath && convertLinkLocale(language.data, asPath, currency.data), 
    options
  )
  return dispatch => {
    dispatch({
      type: ActionType.REDIRECT_URL
    })
  }
}

/**
 * reload page action
 */
export const reloadPath = () => {
  const path = window.location.pathname + window.location.search
  const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
  const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
  Router.push(convertLinkLocale(language.data, path, currency.data))
  return dispatch => {
    dispatch({
      type: ActionType.REDIRECT_URL
    })
  }
}

/**
 * load success action
 * @param actionType
 * @param data
 * @param callback
 * @param message
 * @param path
 * @returns {function(*): Promise<unknown[]>}
 */
export const loadSuccess = (actionType, data, message, path, callback) => {
  return dispatch => {
    let actionList = []
    if (actionType) {
      actionList.push(loadData(actionType, data))
    }
    if (message) {
      actionList.push(show('modal', { message: message }))
    }
    if (path && path !== 'modal' && path !== '/') {
      actionList.push(redirectPath(path))
    }
    if (callback) {
      actionList = [...actionList, ...callback]
    }
    if (actionList.length > 0) {
      const dispatchList = actionList.map(item => item && dispatch(item))
      return Promise.all(dispatchList).finally(() => {
        if (path === 'modal') {window.location.reload()}
        if (path === '/') {window.location.href = "/"}
      })
    }
  }
}

/**
 * load fail action
 * @param message
 * @param path
 * @param callback
 * @returns {function(*): Promise<unknown[]>}
 */
export const loadFail = (message, path, callback, params = {isReload: false} ) => {
  return dispatch => {
    let actionList = []
    console.log('message LOADFAIL', message);
    if (message) {
      const { data } = message
      const { errors = [] } = data || {}
      let isPendingOrderError = false
      if (errors.length > 0) {
        const result = errors.find(
          error =>
            error.code === enumType.apiErrorCode.SYS_CRD_INVALID_PENDING_ORDER
        );
        if (result) {
          isPendingOrderError = true;
        }
      }
      if (isPendingOrderError) {
        actionList.push(dispatch => {
          dispatch({
            type: actionTypes.SET_CREDIT_POINT_CALCULATION_DATA,
            payload: { data: {
              isSuccess: false,
              errors: errors
            }}
          })
        })
      } else {
        actionList.push(show('modal', { message: message || UNDEFINED_ERROR, params }))
      }
    }
    if (path) {
      actionList.push(redirectPath(path))
    }
    if (callback) {
      actionList = [...actionList, ...callback]
    }
    if (actionList.length > 0) {
      const dispatchList = actionList.map(item => item && dispatch(item))
      return Promise.all(dispatchList)
    }
  }
}

export const modalNotify = (message, children) => {
  return async (dispatch) => {
    dispatch(show('notifyModal', { message: message, children }))
  }
}

export const modalLogin = () => {
  return async (dispatch) => {
    dispatch(show('loginModal'))
  }
}

export const startFetchingListingAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_LISTING
    })
  }
}

export const stopFetchingListingAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_LISTING
    })
  }
}



export function getRedirectLink(fromLink = '') {
  return async (dispatch) => {
    try {
      const response = await axiosProviderCms.get(`/frontend/redirectlink/get-destination-link`, {
        params: {
          fromLink
        }
      })

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_REDIRECT_LINK_SUCCESS,
            response.data.data
          )
        )
      } else {
        // dispatch(loadFail(response.errors[0]))
      }
    } catch (error) {
      console.error(error)
      // dispatch(loadFail(error.response))
    }
  }
}

export const startFetchingHomestayAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_HOMESTAY
    })
  }
}

export const stopFetchingHomestayAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_HOMESTAY
    })
  }
}

export const startFetchingHomestayRoomAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.START_FETCHING_HOMESTAY_ROOM
    })
  }
}

export const stopFetchingHomestayRoomAction = () => {
  return dispatch => {
    dispatch({
      type: ActionType.STOP_FETCHING_HOMESTAY_ROOM
    })
  }
}
