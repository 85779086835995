import React from 'react'
import { FormattedMessage } from 'react-intl.macro'
import { FormattedNumber } from 'react-intl'
import { language, country } from './language';
import { currencyUnit } from './currencyUnit';
import routes from './routes'

const passenger = {
  Adult: 'ADT',
  Children: 'CHD',
  Infant: 'INF'
}
const IdType = {
  CCCD:"CCCD/CMND",
  Passport: "Passport"
}
const vietNamCode = 'VNM'
const vietJetCode = 'VJ'
const passengerHotel = {
  Adult: 'adult',
  Children: 'child',
  infant: 'INF'
}

const optionAll = 'all';

const passengerType = [
  {
    value: passenger.Adult,
    key: 'adult',
    label: <FormattedMessage
      id="Enum.Passenger.Adult"
      defaultMessage='Người lớn'
    />,
    description: <FormattedMessage
      id="Enum.Passenger.AdultAge"
      defaultMessage='Từ 12 tuổi'
    />,
    icon: require('@assets/include/svg/adult.svg').default,
    validate: {
      min: <FormattedMessage
        id="Enum.Passenger.Adult.Min"
        defaultMessage='Số hành khách tối thiểu là 1'
      />,
      max: undefined,
    },
  },
  {
    value: passenger.Children,
    key: 'child',
    label: <FormattedMessage
      id="Enum.Passenger.Children"
      defaultMessage='Trẻ em'
    />,
    description: <FormattedMessage
      id="Enum.Passenger.ChildrenAge"
      defaultMessage='Từ 2 đến 11 tuổi'
    />,
    icon: require('@assets/include/svg/child.svg').default,
    validate: {
      min: undefined,
      max: undefined,
    },
  },
  {
    value: passenger.Infant,
    key: 'infant',
    label: <FormattedMessage
      id="Enum.Passenger.Infant"
      defaultMessage='Em bé'
    />,
    description: <FormattedMessage
      id="Enum.Passenger.InfantAge"
      defaultMessage='< 2 tuổi'
    />,
    icon: require('@assets/include/svg/infant.svg').default,
    validate: {
      min: undefined,
      max: <FormattedMessage
        id="Enum.Passenger.Infant.Max"
        defaultMessage='Tổng số trẻ sơ sinh không được vượt quá số người lớn'
      />,
    },
  }
]

const stopover = {
  All: optionAll,
  Direct: 'direct',
  TransitOneStop: 'transit-one-stop',
  TransitMultiStop: 'transit-multi-stop',
}

const stopoverEnum = [
  // {
  //   value: stopover.All,
  //   key: stopover.All,
  //   label: <FormattedMessage
  //     id="Flight.BasicInfo.Filter.All"
  //     defaultMessage='Tất cả'
  //   />
  // },
  {
    value: stopover.Direct,
    key: stopover.Direct,
    label: <FormattedMessage
      id="Flight.BasicInfo.Filter.Direct"
      defaultMessage='Bay thẳng'
    />
  },
  {
    value: stopover.TransitOneStop,
    key: stopover.TransitOneStop,
    label: <FormattedMessage
      id="Flight.BasicInfo.Filter.TransitOneStop"
      defaultMessage='1 điểm dừng'
    />
  },
  {
    value: stopover.TransitMultiStop,
    key: stopover.TransitMultiStop,
    label: <FormattedMessage
      id="Flight.BasicInfo.Filter.TransitMultiStop"
      defaultMessage='2+ điểm dừng'
    />
  },
]

const takeOff = {
  All: optionAll,
  QuarterPass: 'quarterPass',
  HalfPass: 'halfPass',
  QuarterTo: 'quarterTo',
  OClock: 'oClock'
}

const takeOffEnum = [
  // {
  //   value: takeOff.All,
  //   key: takeOff.All,
  //   label: <FormattedMessage
  //     id="Flight.BasicInfo.Filter.All"
  //     defaultMessage='Tất cả'
  //   />
  // },
  {
    value: takeOff.QuarterPass,
    key: takeOff.QuarterPass,
    label: '0.00 - 6.00'
  },
  {
    value: takeOff.HalfPass,
    key: takeOff.HalfPass,
    label: '6.00 - 12.00'
  },
  {
    value: takeOff.QuarterTo,
    key: takeOff.QuarterTo,
    label: '12.00 - 18.00'
  },
  {
    value: takeOff.OClock,
    key: takeOff.OClock,
    label: '18.00 - 24.00'
  }
]

const airline = {
  All: optionAll,
  VietnamAirline: 'VN',
  VietjetAir: 'VJ',
  // Jetstar: 'JQ',
  BambooAirway: 'QH'
}

const airlineEnum = [
  {
    value: airline.VietnamAirline,
    label: 'Vietnam Airlines',
    logo: require('@assets/img/logo/vna-inline.svg').default
  },
  {
    value: airline.VietjetAir,
    label: 'Vietjet Air',
    logo: require('@assets/img/logo/vjair-inline.svg').default
  },
  // {
  //   value: airline.Jetstar,
  //   label: 'Jetstar',
  //   logo: require('@assets/img/logo/jetstar-inline.svg').default
  // },
  {
    value: airline.BambooAirway,
    label: 'Bamboo Airways',
    logo: require('@assets/img/logo/bamboo-inline.svg').default
  }
]


const seatSelection = {
  All: optionAll,
  Economy: 'ECONOMY',
  Business: 'BUSINESS',
  First: 'FIRST'
}

const seatGroupClass = {
  BUSINESS: [
    'Business Classic',
    'Business Flex',
    'SkyBoss',
    'BAMBOOBUSINESS',
    'BUSINESS',
  ],
  ECONOMY: [
    'Eco'
  ]
}

const seatSelectionEnum = [
  {
    value: seatSelection.Economy,
    key: seatSelection.Economy,
    label: <FormattedMessage
      id="EconomyClass"
      defaultMessage='Phổ thông'
    />
  },
  {
    value: seatSelection.Business,
    key: seatSelection.Business,
    label: <FormattedMessage
      id="BusinessClass"
      defaultMessage='Thương gia'
    />
  },
  // {
  //   value: seatSelection.First,
  //   key: seatSelection.First,
  //   label: <FormattedMessage
  //     id="FirstClassCabin"
  //     defaultMessage='Khoang hạng nhất'
  //   />
  // }
]

const sortDirection = {
  NONE: 'NONE',
  ASC: 'ASC',
  DESC: 'DESC',
}

const flightSort = {
  TakeOff: 'takeoff',
  Landing: 'landing',
  Duration: 'duration',
  Price: 'price',
}

const flightSortEnum = [
  {
    value: flightSort.TakeOff,
    key: flightSort.TakeOff,
    label: {
      NONE: < FormattedMessage
        id="Flight.BasicInfo.Sort.TakeOffAsc"
        defaultMessage='Khởi hành'
      />,
      ASC: < FormattedMessage
        id="Flight.BasicInfo.Sort.TakeOffAsc"
        defaultMessage='Khởi hành'
      />,
      DESC: < FormattedMessage
        id="Flight.BasicInfo.Sort.TakeOffAsc"
        defaultMessage='Khởi hành'
      />,
    }
  },
  {
    value: flightSort.Landing,
    key: flightSort.Landing,
    label: {
      NONE: < FormattedMessage
        id="Flight.BasicInfo.Sort.LandingAsc"
        defaultMessage='Giờ đến'
      />,
      ASC: < FormattedMessage
        id="Flight.BasicInfo.Sort.LandingAsc"
        defaultMessage='Giờ đến'
      />,
      DESC: < FormattedMessage
        id="Flight.BasicInfo.Sort.LandingAsc"
        defaultMessage='Giờ đến'
      />,
    }
  },
  {
    value: flightSort.Duration,
    key: flightSort.Duration,
    label: {
      NONE: < FormattedMessage
        id="Flight.BasicInfo.Sort.DurationAsc"
        defaultMessage='Thời gian bay'
      />,
      ASC: < FormattedMessage
        id="Flight.BasicInfo.Sort.DurationAsc"
        defaultMessage='Thời gian bay'
      />,
      DESC: < FormattedMessage
        id="Flight.BasicInfo.Sort.DurationAsc"
        defaultMessage='Thời gian bay'
      />,
    }
  },
  {
    value: flightSort.Price,
    key: flightSort.Price,
    label: {
      NONE: < FormattedMessage
        id="Flight.BasicInfo.Sort.Price"
        defaultMessage='Giá'
      />,
      ASC: < FormattedMessage
        id="Flight.BasicInfo.Sort.PriceAsc"
        defaultMessage='Giá tăng dần'
      />,
      DESC: < FormattedMessage
        id="Flight.BasicInfo.Sort.PriceDesc"
        defaultMessage='Giá giảm dần'
      />,
    }
  },
]

const sortType = {
  NoSort: 'all',
  BestSeller: 'soldQuantity',
  Latest: 'createdDate',
  MinPrice: 'salePriceAsc',
  MaxPrice: 'salePriceDesc',
  HotDeals: 'isSpecial'
}

const voucherFilter = [
  {
    value: sortType.NoSort,
    orderBy: '',
    orderByDesc: false,
    key: 'all',
    label: <FormattedMessage
      id="Voucher.Search.Filter.NoSort"
      defaultMessage="Không sắp xếp"
    />
  },
  {
    value: sortType.Latest,
    orderBy: 'createdDate',
    orderByDesc: true,
    key: 'createdDate',
    label: <FormattedMessage
      id="Voucher.Search.Filter.Latest"
      defaultMessage='Mới nhất'
    />
  },
  {
    value: sortType.MinPrice,
    orderBy: 'salePrice',
    orderByDesc: false,
    key: 'salePriceAsc',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceLowHigh"
      defaultMessage='Giá thấp đến cao'
    />
  },
  {
    value: sortType.MaxPrice,
    orderBy: 'salePrice',
    key: 'salePriceDesc',
    orderByDesc: true,
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceHighLow"
      defaultMessage='Giá cao đến thấp'
    />
  },
  {
    value: sortType.HotDeals,
    orderBy: 'isSpecial',
    key: 'isSpecial',
    orderByDesc: true,
    label: <FormattedMessage
      id="Voucher.Search.Filter.HotDeals"
      defaultMessage='Ưu đãi nổi bật'
    />
  },
  {
    value: sortType.BestSeller,
    orderBy: 'soldQuantity',
    key: 'soldQuantity',
    orderByDesc: true,
    label: <FormattedMessage
      id="Voucher.Search.Filter.BestSeller"
      defaultMessage='Sản phầm bán chạy'
    />
  }

]

const tourFilter = [
  {
    value: 'all',
    label: <FormattedMessage
      id="Filter.Latest"
      defaultMessage='Mới nhất'
    />
  },
  {
    value: 'special',
    label: <FormattedMessage
      id="Filter.MostOutstanding"
      defaultMessage='Nổi bật nhất'
    />
  }
]

const tourLength = [
  {
    value: '1',
    label: 'Đi trong ngày'
  },
  {
    value: '2',
    label: '2 ngày 1 đêm'
  },
  {
    value: '3',
    label: '3 ngày 2 đêm'
  },
  {
    value: '4',
    label: '4 ngày 3 đêm'
  },
  {
    value: '5',
    label: '5 ngày 1 đêm'
  }
]

const voucherTypes = [
  {
    value: 'type1',
    label: 'Voucher Type 1'
  },
  {
    value: 'type2',
    label: 'Voucher Type 2'
  }
]

const filterPrices = [
  {
    value: 'all',
    label: <FormattedMessage
      id="Filter.All"
      defaultMessage='Tất cả'
    />,
    MinPrice: undefined,
    MaxPrice: undefined
  },
  {
    value: '-5000000',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Lower"
      defaultMessage='Dưới 5,000,000đ'
    />,
    MinPrice: undefined,
    MaxPrice: 5000000
  },
  {
    value: '5000000-10000000',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Between5MiTo10Mi"
      defaultMessage={`Từ {MinPrice}đ đến {MaxPrice}đ`}
      values={{
        MinPrice: <FormattedNumber value={5000000} />,
        MaxPrice: <FormattedNumber value={10000000} />,
      }}
    />,
    MinPrice: 5000000,
    MaxPrice: 10000000
  },
  {
    value: '10000000-20000000',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Between10MiTo20Mi"
      defaultMessage='Từ 10,000,000đ đến 20,000,000đ'
    />,
    MinPrice: 10000000,
    MaxPrice: 20000000
  },
  {
    value: '20000000-30000000',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Between20MiTo30Mi"
      defaultMessage='Từ 20,000,000đ đến 30,000,000đ'
    />,
    MinPrice: 20000000,
    MaxPrice: 30000000
  },
  {
    value: '30000000-50000000',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Between30MiTo50Mi"
      defaultMessage='Từ 30,000,000đ đến 50,000,000đ'
    />,
    MinPrice: 30000000,
    MaxPrice: 50000000
  },
  {
    value: '50000000+',
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceRange.Higher"
      defaultMessage='Trên 50,000,000đ'
    />,
    MinPrice: 50000000,
    MaxPrice: undefined
  }
]

const paymentStep = {
  Cart: 'cart',
  FillForm: 'fill-form',
  Payment: 'payment',
  Complete: 'complete',
}

const paymentStepEnum = [
  {
    title: <FormattedMessage
      id="Flight.Step.FillForm"
      defaultMessage='Điền thông tin'
    />,
    value: paymentStep.FillForm
  },
  {
    title: <FormattedMessage
      id="Flight.Step.Payment"
      defaultMessage='Thanh Toán'
    />,
    value: paymentStep.Payment
  },
  {
    title: <FormattedMessage
      id="Flight.Step.Complete"
      defaultMessage='Hoàn thành'
    />,
    value: paymentStep.Complete
  },
]

const gender = {
  Male: '1',
  Female: '0'
}

const gender3s = {
  Male: 'Mr',
  Female: 'Mrs',
  Other: 'Mr/Mrs'
}

const genderEnum = [
  {
    key: gender.Male,
    value: gender.Male,
    label: <FormattedMessage
      id="Flight.FillForm.Man"
      defaultMessage='Ông'
    />
  },
  {
    key: gender.Female,
    value: gender.Female,
    label: <FormattedMessage
      id="Flight.FillForm.Women"
      defaultMessage='Bà'
    />
  }
]

const genderEnumForChild = [
  {
    key: gender.Male,
    value: gender.Male,
    label: <FormattedMessage
      id="Flight.FillForm.Child.Man"
      defaultMessage='Nam'
    />
  },
  {
    key: gender.Female,
    value: gender.Female,
    label: <FormattedMessage
      id="Flight.FillForm.Child.Women"
      defaultMessage='Nữ'
    />
  }
]

const genderEnum3s = [
  {
    key: gender3s.Male,
    value: gender3s.Male,
    label: <FormattedMessage
      id="Flight.FillForm.Man"
      defaultMessage='Ông'
    />
  },
  {
    key: gender3s.Female,
    value: gender3s.Female,
    label: <FormattedMessage
      id="Flight.FillForm.Women"
      defaultMessage='Bà'
    />
  },
  {
    key: gender3s.Other,
    value: gender3s.Other,
    label: <FormattedMessage
      id="Flight.FillForm.Other"
      defaultMessage='Khác'
    />
  }
]

const genderEnumProfile = [
  {
    key: 'Male',
    value: "M",
    label: <FormattedMessage
      id="InfoGenderType.Man"
      defaultMessage='Nam'
    />
  },
  {
    key: 'Female',
    value: "F",
    label: <FormattedMessage
      id="InfoGenderType.Woman"
      defaultMessage='Nữ'
    />
  },
  {
    key: 'Other',
    value: "O",
    label: <FormattedMessage
      id="Flight.FillForm.Other"
      defaultMessage='Khác'
    />
  }
]

const paymentMethod = {
  Domestic: 'DOMESTIC',
  International: 'INTERNATIONAL',
  Installment: 'INSTALLMENT',
  QRCode: 'QR',
  PayAtHotel: 'pay_at_hotel',
  EWallet: 'E_WALLET',
}

const paymentType = {
  ONEPAY: 'ONEPAY',
  ONEPAY_INSTALLMENT: 'ONEPAY_INSTALLMENT',
}

const paymentMethodEnum = [
  {
    value: paymentMethod.Domestic,
    label: <FormattedMessage
      id="PaymentMethod.ATM"
      defaultMessage='Thẻ ATM / Tài khoản ngân hàng'
    />
  },
  {
    value: paymentMethod.International,
    label: <FormattedMessage
      id="PaymentMethod.CreditCard"
      defaultMessage='Thẻ tín dụng ghi nợ'
    />
  },
  {
    value: paymentMethod.Installment,
    label: <FormattedMessage
      id="PaymentMethod.Installment"
      defaultMessage='Thanh toán trả góp'
    />
  },
  {
    value: paymentMethod.QRCode,
    label: <FormattedMessage
      id="PaymentMethod.QRCode"
      defaultMessage='Thanh toán bằng QRCode'
    />
  },
  {
    value: paymentMethod.EWallet,
    label: <FormattedMessage
      id="PaymentMethod.EWallet"
      defaultMessage='Thanh toán bằng Ví điện tử'
    />
  }
]

const cardType = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  JCB: 'JCB'
}

const cardTypeEnum = [
  {
    value: cardType.Visa,
    label: 'Visa',
    icon: require('@assets/include/svg/visa.svg').default
  },
  {
    value: cardType.MasterCard,
    label: 'MasterCard',
    icon: require('@assets/include/svg/master-card.svg').default
  },
  {
    value: cardType.JCB,
    label: 'JCB',
    icon: require('@assets/include/svg/jcb.svg').default
  }
]

const orderStatus = {
  Success: 'success',
  Fail: 'fail',
  Pending: 'pending'
}

const itineraryType = {
  OneWay: '1',
  Return: '2'
}

const itineraryTypeEnum = [
  {
    value: itineraryType.Return,
    label: <FormattedMessage
      id="Enum.ItineraryType.Return"
      defaultMessage="Khứ hồi"
    />
  },
  {
    value: itineraryType.OneWay,
    label: <FormattedMessage
      id="Enum.ItineraryType.OneWay"
      defaultMessage="Một chiều"
    />
  }
]

const prefixPhone = {
  Vn: '+84'
}

const prefixPhoneEnum = [
  {
    value: prefixPhone.Vn,
    key: prefixPhone.Vn,
    label: '+84'
  }
]

const prefixPhoneEnums = [
  {
      "COUNTRY": "Canada",
      "COUNTRY_CODE": "+1",
      "ISO_CODES": "CA / CAN"
  },
  {
      "COUNTRY": "Kazakhstan",
      "COUNTRY_CODE": "+7",
      "ISO_CODES": "KZ / KAZ"
  },
  {
      "COUNTRY": "Egypt",
      "COUNTRY_CODE": "+20",
      "ISO_CODES": "EG / EGY"
  },
  {
      "COUNTRY": "South Africa",
      "COUNTRY_CODE": "+27",
      "ISO_CODES": "ZA / ZAF"
  },
  {
      "COUNTRY": "Greece",
      "COUNTRY_CODE": "+30",
      "ISO_CODES": "GR / GRC"
  },
  {
      "COUNTRY": "Netherlands",
      "COUNTRY_CODE": "+31",
      "ISO_CODES": "NL / NLD"
  },
  {
      "COUNTRY": "Belgium",
      "COUNTRY_CODE": "+32",
      "ISO_CODES": "BE / BEL"
  },
  {
      "COUNTRY": "France",
      "COUNTRY_CODE": "+33",
      "ISO_CODES": "FR / FRA"
  },
  {
      "COUNTRY": "Spain",
      "COUNTRY_CODE": "+34",
      "ISO_CODES": "ES / ESP"
  },
  {
      "COUNTRY": "Hungary",
      "COUNTRY_CODE": "+36",
      "ISO_CODES": "HU / HUN"
  },
  {
      "COUNTRY": "Italy",
      "COUNTRY_CODE": "+39",
      "ISO_CODES": "IT / ITA"
  },
  {
      "COUNTRY": "Romania",
      "COUNTRY_CODE": "+40",
      "ISO_CODES": "RO / ROU"
  },
  {
      "COUNTRY": "Switzerland",
      "COUNTRY_CODE": "+41",
      "ISO_CODES": "CH / CHE"
  },
  {
      "COUNTRY": "Austria",
      "COUNTRY_CODE": "+43",
      "ISO_CODES": "AT / AUT"
  },
  {
      "COUNTRY": "United Kingdom",
      "COUNTRY_CODE": "+44",
      "ISO_CODES": "GB / GBR"
  },
  {
      "COUNTRY": "Denmark",
      "COUNTRY_CODE": "+45",
      "ISO_CODES": "DK / DNK"
  },
  {
      "COUNTRY": "Sweden",
      "COUNTRY_CODE": "+46",
      "ISO_CODES": "SE / SWE"
  },
  {
      "COUNTRY": "Norway",
      "COUNTRY_CODE": "+47",
      "ISO_CODES": "NO / NOR"
  },
  {
      "COUNTRY": "Poland",
      "COUNTRY_CODE": "+48",
      "ISO_CODES": "PL / POL"
  },
  {
      "COUNTRY": "Germany",
      "COUNTRY_CODE": "+49",
      "ISO_CODES": "DE / DEU"
  },
  {
      "COUNTRY": "Peru",
      "COUNTRY_CODE": "+51",
      "ISO_CODES": "PE / PER"
  },
  {
      "COUNTRY": "Mexico",
      "COUNTRY_CODE": "+52",
      "ISO_CODES": "MX / MEX"
  },
  {
      "COUNTRY": "Cuba",
      "COUNTRY_CODE": "+53",
      "ISO_CODES": "CU / CUB"
  },
  {
      "COUNTRY": "Argentina",
      "COUNTRY_CODE": "+54",
      "ISO_CODES": "AR / ARG"
  },
  {
      "COUNTRY": "Brazil",
      "COUNTRY_CODE": "+55",
      "ISO_CODES": "BR / BRA"
  },
  {
      "COUNTRY": "Chile",
      "COUNTRY_CODE": "+56",
      "ISO_CODES": "CL / CHL"
  },
  {
      "COUNTRY": "Colombia",
      "COUNTRY_CODE": "+57",
      "ISO_CODES": "CO / COL"
  },
  {
      "COUNTRY": "Venezuela",
      "COUNTRY_CODE": "+58",
      "ISO_CODES": "VE / VEN"
  },
  {
      "COUNTRY": "Malaysia",
      "COUNTRY_CODE": "+60",
      "ISO_CODES": "MY / MYS"
  },
  {
      "COUNTRY": "Australia",
      "COUNTRY_CODE": "+61",
      "ISO_CODES": "AU / AUS"
  },
  {
      "COUNTRY": "Indonesia",
      "COUNTRY_CODE": "+62",
      "ISO_CODES": "ID / IDN"
  },
  {
      "COUNTRY": "Philippines",
      "COUNTRY_CODE": "+63",
      "ISO_CODES": "PH / PHL"
  },
  {
      "COUNTRY": "New Zealand",
      "COUNTRY_CODE": "+64",
      "ISO_CODES": "NZ / NZL"
  },
  {
      "COUNTRY": "Singapore",
      "COUNTRY_CODE": "+65",
      "ISO_CODES": "SG / SGP"
  },
  {
      "COUNTRY": "Thailand",
      "COUNTRY_CODE": "+66",
      "ISO_CODES": "TH / THA"
  },
  {
      "COUNTRY": "Japan",
      "COUNTRY_CODE": "+81",
      "ISO_CODES": "JP / JPN"
  },
  {
      "COUNTRY": "South Korea",
      "COUNTRY_CODE": "+82",
      "ISO_CODES": "KR / KOR"
  },
  {
      "COUNTRY": "Vietnam",
      "COUNTRY_CODE": "+84",
      "ISO_CODES": "VN / VNM"
  },
  {
      "COUNTRY": "China",
      "COUNTRY_CODE": "+86",
      "ISO_CODES": "CN / CHN"
  },
  {
      "COUNTRY": "Turkey",
      "COUNTRY_CODE": "+90",
      "ISO_CODES": "TR / TUR"
  },
  {
      "COUNTRY": "India",
      "COUNTRY_CODE": "+91",
      "ISO_CODES": "IN / IND"
  },
  {
      "COUNTRY": "Pakistan",
      "COUNTRY_CODE": "+92",
      "ISO_CODES": "PK / PAK"
  },
  {
      "COUNTRY": "Afghanistan",
      "COUNTRY_CODE": "+93",
      "ISO_CODES": "AF / AFG"
  },
  {
      "COUNTRY": "Sri Lanka",
      "COUNTRY_CODE": "+94",
      "ISO_CODES": "LK / LKA"
  },
  {
      "COUNTRY": "Myanmar",
      "COUNTRY_CODE": "+95",
      "ISO_CODES": "MM / MMR"
  },
  {
      "COUNTRY": "Iran",
      "COUNTRY_CODE": "+98",
      "ISO_CODES": "IR / IRN"
  },
  {
      "COUNTRY": "South Sudan",
      "COUNTRY_CODE": "+211",
      "ISO_CODES": "SS / SSD"
  },
  {
      "COUNTRY": "Morocco",
      "COUNTRY_CODE": "+212",
      "ISO_CODES": "MA / MAR"
  },
  {
      "COUNTRY": "Algeria",
      "COUNTRY_CODE": "+213",
      "ISO_CODES": "DZ / DZA"
  },
  {
      "COUNTRY": "Tunisia",
      "COUNTRY_CODE": "+216",
      "ISO_CODES": "TN / TUN"
  },
  {
      "COUNTRY": "Libya",
      "COUNTRY_CODE": "+218",
      "ISO_CODES": "LY / LBY"
  },
  {
      "COUNTRY": "Gambia",
      "COUNTRY_CODE": "+220",
      "ISO_CODES": "GM / GMB"
  },
  {
      "COUNTRY": "Senegal",
      "COUNTRY_CODE": "+221",
      "ISO_CODES": "SN / SEN"
  },
  {
      "COUNTRY": "Mauritania",
      "COUNTRY_CODE": "+222",
      "ISO_CODES": "MR / MRT"
  },
  {
      "COUNTRY": "Mali",
      "COUNTRY_CODE": "+223",
      "ISO_CODES": "ML / MLI"
  },
  {
      "COUNTRY": "Guinea",
      "COUNTRY_CODE": "+224",
      "ISO_CODES": "GN / GIN"
  },
  {
      "COUNTRY": "Ivory Coast",
      "COUNTRY_CODE": "+225",
      "ISO_CODES": "CI / CIV"
  },
  {
      "COUNTRY": "Burkina Faso",
      "COUNTRY_CODE": "+226",
      "ISO_CODES": "BF / BFA"
  },
  {
      "COUNTRY": "Niger",
      "COUNTRY_CODE": "+227",
      "ISO_CODES": "NE / NER"
  },
  {
      "COUNTRY": "Togo",
      "COUNTRY_CODE": "+228",
      "ISO_CODES": "TG / TGO"
  },
  {
      "COUNTRY": "Benin",
      "COUNTRY_CODE": "+229",
      "ISO_CODES": "BJ / BEN"
  },
  {
      "COUNTRY": "Mauritius",
      "COUNTRY_CODE": "+230",
      "ISO_CODES": "MU / MUS"
  },
  {
      "COUNTRY": "Liberia",
      "COUNTRY_CODE": "+231",
      "ISO_CODES": "LR / LBR"
  },
  {
      "COUNTRY": "Sierra Leone",
      "COUNTRY_CODE": "+232",
      "ISO_CODES": "SL / SLE"
  },
  {
      "COUNTRY": "Ghana",
      "COUNTRY_CODE": "+233",
      "ISO_CODES": "GH / GHA"
  },
  {
      "COUNTRY": "Nigeria",
      "COUNTRY_CODE": "+234",
      "ISO_CODES": "NG / NGA"
  },
  {
      "COUNTRY": "Chad",
      "COUNTRY_CODE": "+235",
      "ISO_CODES": "TD / TCD"
  },
  {
      "COUNTRY": "Central African Republic",
      "COUNTRY_CODE": "+236",
      "ISO_CODES": "CF / CAF"
  },
  {
      "COUNTRY": "Cameroon",
      "COUNTRY_CODE": "+237",
      "ISO_CODES": "CM / CMR"
  },
  {
      "COUNTRY": "Cape Verde",
      "COUNTRY_CODE": "+238",
      "ISO_CODES": "CV / CPV"
  },
  {
      "COUNTRY": "Sao Tome and Principe",
      "COUNTRY_CODE": "+239",
      "ISO_CODES": "ST / STP"
  },
  {
      "COUNTRY": "Equatorial Guinea",
      "COUNTRY_CODE": "+240",
      "ISO_CODES": "GQ / GNQ"
  },
  {
      "COUNTRY": "Gabon",
      "COUNTRY_CODE": "+241",
      "ISO_CODES": "GA / GAB"
  },
  {
      "COUNTRY": "Republic of the Congo",
      "COUNTRY_CODE": "+242",
      "ISO_CODES": "CG / COG"
  },
  {
      "COUNTRY": "Democratic Republic of the Congo",
      "COUNTRY_CODE": "+243",
      "ISO_CODES": "CD / COD"
  },
  {
      "COUNTRY": "Angola",
      "COUNTRY_CODE": "+244",
      "ISO_CODES": "AO / AGO"
  },
  {
      "COUNTRY": "Guinea-Bissau",
      "COUNTRY_CODE": "+245",
      "ISO_CODES": "GW / GNB"
  },
  {
      "COUNTRY": "British Indian Ocean Territory",
      "COUNTRY_CODE": "+246",
      "ISO_CODES": "IO / IOT"
  },
  {
      "COUNTRY": "Seychelles",
      "COUNTRY_CODE": "+248",
      "ISO_CODES": "SC / SYC"
  },
  {
      "COUNTRY": "Sudan",
      "COUNTRY_CODE": "+249",
      "ISO_CODES": "SD / SDN"
  },
  {
      "COUNTRY": "Rwanda",
      "COUNTRY_CODE": "+250",
      "ISO_CODES": "RW / RWA"
  },
  {
      "COUNTRY": "Ethiopia",
      "COUNTRY_CODE": "+251",
      "ISO_CODES": "ET / ETH"
  },
  {
      "COUNTRY": "Somalia",
      "COUNTRY_CODE": "+252",
      "ISO_CODES": "SO / SOM"
  },
  {
      "COUNTRY": "Djibouti",
      "COUNTRY_CODE": "+253",
      "ISO_CODES": "DJ / DJI"
  },
  {
      "COUNTRY": "Kenya",
      "COUNTRY_CODE": "+254",
      "ISO_CODES": "KE / KEN"
  },
  {
      "COUNTRY": "Tanzania",
      "COUNTRY_CODE": "+255",
      "ISO_CODES": "TZ / TZA"
  },
  {
      "COUNTRY": "Uganda",
      "COUNTRY_CODE": "+256",
      "ISO_CODES": "UG / UGA"
  },
  {
      "COUNTRY": "Burundi",
      "COUNTRY_CODE": "+257",
      "ISO_CODES": "BI / BDI"
  },
  {
      "COUNTRY": "Mozambique",
      "COUNTRY_CODE": "+258",
      "ISO_CODES": "MZ / MOZ"
  },
  {
      "COUNTRY": "Zambia",
      "COUNTRY_CODE": "+260",
      "ISO_CODES": "ZM / ZMB"
  },
  {
      "COUNTRY": "Madagascar",
      "COUNTRY_CODE": "+261",
      "ISO_CODES": "MG / MDG"
  },
  {
      "COUNTRY": "Mayotte",
      "COUNTRY_CODE": "+262",
      "ISO_CODES": "YT / MYT"
  },
  {
      "COUNTRY": "Zimbabwe",
      "COUNTRY_CODE": "+263",
      "ISO_CODES": "ZW / ZWE"
  },
  {
      "COUNTRY": "Namibia",
      "COUNTRY_CODE": "+264",
      "ISO_CODES": "NA / NAM"
  },
  {
      "COUNTRY": "Malawi",
      "COUNTRY_CODE": "+265",
      "ISO_CODES": "MW / MWI"
  },
  {
      "COUNTRY": "Lesotho",
      "COUNTRY_CODE": "+266",
      "ISO_CODES": "LS / LSO"
  },
  {
      "COUNTRY": "Botswana",
      "COUNTRY_CODE": "+267",
      "ISO_CODES": "BW / BWA"
  },
  {
      "COUNTRY": "Swaziland",
      "COUNTRY_CODE": "+268",
      "ISO_CODES": "SZ / SWZ"
  },
  {
      "COUNTRY": "Comoros",
      "COUNTRY_CODE": "+269",
      "ISO_CODES": "KM / COM"
  },
  {
      "COUNTRY": "Saint Helena",
      "COUNTRY_CODE": "+290",
      "ISO_CODES": "SH / SHN"
  },
  {
      "COUNTRY": "Eritrea",
      "COUNTRY_CODE": "+291",
      "ISO_CODES": "ER / ERI"
  },
  {
      "COUNTRY": "Aruba",
      "COUNTRY_CODE": "+297",
      "ISO_CODES": "AW / ABW"
  },
  {
      "COUNTRY": "Faroe Islands",
      "COUNTRY_CODE": "+298",
      "ISO_CODES": "FO / FRO"
  },
  {
      "COUNTRY": "Greenland",
      "COUNTRY_CODE": "+299",
      "ISO_CODES": "GL / GRL"
  },
  {
      "COUNTRY": "Gibraltar",
      "COUNTRY_CODE": "+350",
      "ISO_CODES": "GI / GIB"
  },
  {
      "COUNTRY": "Portugal",
      "COUNTRY_CODE": "+351",
      "ISO_CODES": "PT / PRT"
  },
  {
      "COUNTRY": "Luxembourg",
      "COUNTRY_CODE": "+352",
      "ISO_CODES": "LU / LUX"
  },
  {
      "COUNTRY": "Ireland",
      "COUNTRY_CODE": "+353",
      "ISO_CODES": "IE / IRL"
  },
  {
      "COUNTRY": "Iceland",
      "COUNTRY_CODE": "+354",
      "ISO_CODES": "IS / ISL"
  },
  {
      "COUNTRY": "Albania",
      "COUNTRY_CODE": "+355",
      "ISO_CODES": "AL / ALB"
  },
  {
      "COUNTRY": "Malta",
      "COUNTRY_CODE": "+356",
      "ISO_CODES": "MT / MLT"
  },
  {
      "COUNTRY": "Cyprus",
      "COUNTRY_CODE": "+357",
      "ISO_CODES": "CY / CYP"
  },
  {
      "COUNTRY": "Finland",
      "COUNTRY_CODE": "+358",
      "ISO_CODES": "FI / FIN"
  },
  {
      "COUNTRY": "Bulgaria",
      "COUNTRY_CODE": "+359",
      "ISO_CODES": "BG / BGR"
  },
  {
      "COUNTRY": "Lithuania",
      "COUNTRY_CODE": "+370",
      "ISO_CODES": "LT / LTU"
  },
  {
      "COUNTRY": "Latvia",
      "COUNTRY_CODE": "+371",
      "ISO_CODES": "LV / LVA"
  },
  {
      "COUNTRY": "Estonia",
      "COUNTRY_CODE": "+372",
      "ISO_CODES": "EE / EST"
  },
  {
      "COUNTRY": "Moldova",
      "COUNTRY_CODE": "+373",
      "ISO_CODES": "MD / MDA"
  },
  {
      "COUNTRY": "Armenia",
      "COUNTRY_CODE": "+374",
      "ISO_CODES": "AM / ARM"
  },
  {
      "COUNTRY": "Belarus",
      "COUNTRY_CODE": "+375",
      "ISO_CODES": "BY / BLR"
  },
  {
      "COUNTRY": "Andorra",
      "COUNTRY_CODE": "+376",
      "ISO_CODES": "AD / AND"
  },
  {
      "COUNTRY": "Monaco",
      "COUNTRY_CODE": "+377",
      "ISO_CODES": "MC / MCO"
  },
  {
      "COUNTRY": "San Marino",
      "COUNTRY_CODE": "+378",
      "ISO_CODES": "SM / SMR"
  },
  {
      "COUNTRY": "Vatican",
      "COUNTRY_CODE": "+379",
      "ISO_CODES": "VA / VAT"
  },
  {
      "COUNTRY": "Ukraine",
      "COUNTRY_CODE": "+380",
      "ISO_CODES": "UA / UKR"
  },
  {
      "COUNTRY": "Serbia",
      "COUNTRY_CODE": "+381",
      "ISO_CODES": "RS / SRB"
  },
  {
      "COUNTRY": "Montenegro",
      "COUNTRY_CODE": "+382",
      "ISO_CODES": "ME / MNE"
  },
  {
      "COUNTRY": "Kosovo",
      "COUNTRY_CODE": "+383",
      "ISO_CODES": "XK / XKX"
  },
  {
      "COUNTRY": "Croatia",
      "COUNTRY_CODE": "+385",
      "ISO_CODES": "HR / HRV"
  },
  {
      "COUNTRY": "Slovenia",
      "COUNTRY_CODE": "+386",
      "ISO_CODES": "SI / SVN"
  },
  {
      "COUNTRY": "Bosnia and Herzegovina",
      "COUNTRY_CODE": "+387",
      "ISO_CODES": "BA / BIH"
  },
  {
      "COUNTRY": "Macedonia",
      "COUNTRY_CODE": "+389",
      "ISO_CODES": "MK / MKD"
  },
  {
      "COUNTRY": "Czech Republic",
      "COUNTRY_CODE": "+420",
      "ISO_CODES": "CZ / CZE"
  },
  {
      "COUNTRY": "Slovakia",
      "COUNTRY_CODE": "+421",
      "ISO_CODES": "SK / SVK"
  },
  {
      "COUNTRY": "Liechtenstein",
      "COUNTRY_CODE": "+423",
      "ISO_CODES": "LI / LIE"
  },
  {
      "COUNTRY": "Falkland Islands",
      "COUNTRY_CODE": "+500",
      "ISO_CODES": "FK / FLK"
  },
  {
      "COUNTRY": "Belize",
      "COUNTRY_CODE": "+501",
      "ISO_CODES": "BZ / BLZ"
  },
  {
      "COUNTRY": "Guatemala",
      "COUNTRY_CODE": "+502",
      "ISO_CODES": "GT / GTM"
  },
  {
      "COUNTRY": "El Salvador",
      "COUNTRY_CODE": "+503",
      "ISO_CODES": "SV / SLV"
  },
  {
      "COUNTRY": "Honduras",
      "COUNTRY_CODE": "+504",
      "ISO_CODES": "HN / HND"
  },
  {
      "COUNTRY": "Nicaragua",
      "COUNTRY_CODE": "+505",
      "ISO_CODES": "NI / NIC"
  },
  {
      "COUNTRY": "Costa Rica",
      "COUNTRY_CODE": "+506",
      "ISO_CODES": "CR / CRI"
  },
  {
      "COUNTRY": "Panama",
      "COUNTRY_CODE": "+507",
      "ISO_CODES": "PA / PAN"
  },
  {
      "COUNTRY": "Saint Pierre and Miquelon",
      "COUNTRY_CODE": "+508",
      "ISO_CODES": "PM / SPM"
  },
  {
      "COUNTRY": "Haiti",
      "COUNTRY_CODE": "+509",
      "ISO_CODES": "HT / HTI"
  },
  {
      "COUNTRY": "Saint Barthelemy",
      "COUNTRY_CODE": "+590",
      "ISO_CODES": "BL / BLM"
  },
  {
      "COUNTRY": "Bolivia",
      "COUNTRY_CODE": "+591",
      "ISO_CODES": "BO / BOL"
  },
  {
      "COUNTRY": "Guyana",
      "COUNTRY_CODE": "+592",
      "ISO_CODES": "GY / GUY"
  },
  {
      "COUNTRY": "Ecuador",
      "COUNTRY_CODE": "+593",
      "ISO_CODES": "EC / ECU"
  },
  {
      "COUNTRY": "Paraguay",
      "COUNTRY_CODE": "+595",
      "ISO_CODES": "PY / PRY"
  },
  {
      "COUNTRY": "Suriname",
      "COUNTRY_CODE": "+597",
      "ISO_CODES": "SR / SUR"
  },
  {
      "COUNTRY": "Uruguay",
      "COUNTRY_CODE": "+598",
      "ISO_CODES": "UY / URY"
  },
  {
      "COUNTRY": "Curacao",
      "COUNTRY_CODE": "+599",
      "ISO_CODES": "CW / CUW"
  },
  {
      "COUNTRY": "East Timor",
      "COUNTRY_CODE": "+670",
      "ISO_CODES": "TL / TLS"
  },
  {
      "COUNTRY": "Antarctica",
      "COUNTRY_CODE": "+672",
      "ISO_CODES": "AQ / ATA"
  },
  {
      "COUNTRY": "Brunei",
      "COUNTRY_CODE": "+673",
      "ISO_CODES": "BN / BRN"
  },
  {
      "COUNTRY": "Nauru",
      "COUNTRY_CODE": "+674",
      "ISO_CODES": "NR / NRU"
  },
  {
      "COUNTRY": "Papua New Guinea",
      "COUNTRY_CODE": "+675",
      "ISO_CODES": "PG / PNG"
  },
  {
      "COUNTRY": "Tonga",
      "COUNTRY_CODE": "+676",
      "ISO_CODES": "TO / TON"
  },
  {
      "COUNTRY": "Solomon Islands",
      "COUNTRY_CODE": "+677",
      "ISO_CODES": "SB / SLB"
  },
  {
      "COUNTRY": "Vanuatu",
      "COUNTRY_CODE": "+678",
      "ISO_CODES": "VU / VUT"
  },
  {
      "COUNTRY": "Fiji",
      "COUNTRY_CODE": "+679",
      "ISO_CODES": "FJ / FJI"
  },
  {
      "COUNTRY": "Palau",
      "COUNTRY_CODE": "+680",
      "ISO_CODES": "PW / PLW"
  },
  {
      "COUNTRY": "Wallis and Futuna",
      "COUNTRY_CODE": "+681",
      "ISO_CODES": "WF / WLF"
  },
  {
      "COUNTRY": "Cook Islands",
      "COUNTRY_CODE": "+682",
      "ISO_CODES": "CK / COK"
  },
  {
      "COUNTRY": "Niue",
      "COUNTRY_CODE": "+683",
      "ISO_CODES": "NU / NIU"
  },
  {
      "COUNTRY": "Samoa",
      "COUNTRY_CODE": "+685",
      "ISO_CODES": "WS / WSM"
  },
  {
      "COUNTRY": "Kiribati",
      "COUNTRY_CODE": "+686",
      "ISO_CODES": "KI / KIR"
  },
  {
      "COUNTRY": "New Caledonia",
      "COUNTRY_CODE": "+687",
      "ISO_CODES": "NC / NCL"
  },
  {
      "COUNTRY": "Tuvalu",
      "COUNTRY_CODE": "+688",
      "ISO_CODES": "TV / TUV"
  },
  {
      "COUNTRY": "French Polynesia",
      "COUNTRY_CODE": "+689",
      "ISO_CODES": "PF / PYF"
  },
  {
      "COUNTRY": "Tokelau",
      "COUNTRY_CODE": "+690",
      "ISO_CODES": "TK / TKL"
  },
  {
      "COUNTRY": "Micronesia",
      "COUNTRY_CODE": "+691",
      "ISO_CODES": "FM / FSM"
  },
  {
      "COUNTRY": "Marshall Islands",
      "COUNTRY_CODE": "+692",
      "ISO_CODES": "MH / MHL"
  },
  {
      "COUNTRY": "North Korea",
      "COUNTRY_CODE": "+850",
      "ISO_CODES": "KP / PRK"
  },
  {
      "COUNTRY": "Hong Kong",
      "COUNTRY_CODE": "+852",
      "ISO_CODES": "HK / HKG"
  },
  {
      "COUNTRY": "Macau",
      "COUNTRY_CODE": "+853",
      "ISO_CODES": "MO / MAC"
  },
  {
      "COUNTRY": "Cambodia",
      "COUNTRY_CODE": "+855",
      "ISO_CODES": "KH / KHM"
  },
  {
      "COUNTRY": "Laos",
      "COUNTRY_CODE": "+856",
      "ISO_CODES": "LA / LAO"
  },
  {
      "COUNTRY": "Bangladesh",
      "COUNTRY_CODE": "+880",
      "ISO_CODES": "BD / BGD"
  },
  {
      "COUNTRY": "Taiwan",
      "COUNTRY_CODE": "+886",
      "ISO_CODES": "TW / TWN"
  },
  {
      "COUNTRY": "Maldives",
      "COUNTRY_CODE": "+960",
      "ISO_CODES": "MV / MDV"
  },
  {
      "COUNTRY": "Lebanon",
      "COUNTRY_CODE": "+961",
      "ISO_CODES": "LB / LBN"
  },
  {
      "COUNTRY": "Jordan",
      "COUNTRY_CODE": "+962",
      "ISO_CODES": "JO / JOR"
  },
  {
      "COUNTRY": "Syria",
      "COUNTRY_CODE": "+963",
      "ISO_CODES": "SY / SYR"
  },
  {
      "COUNTRY": "Iraq",
      "COUNTRY_CODE": "+964",
      "ISO_CODES": "IQ / IRQ"
  },
  {
      "COUNTRY": "Kuwait",
      "COUNTRY_CODE": "+965",
      "ISO_CODES": "KW / KWT"
  },
  {
      "COUNTRY": "Saudi Arabia",
      "COUNTRY_CODE": "+966",
      "ISO_CODES": "SA / SAU"
  },
  {
      "COUNTRY": "Yemen",
      "COUNTRY_CODE": "+967",
      "ISO_CODES": "YE / YEM"
  },
  {
      "COUNTRY": "Oman",
      "COUNTRY_CODE": "+968",
      "ISO_CODES": "OM / OMN"
  },
  {
      "COUNTRY": "Palestine",
      "COUNTRY_CODE": "+970",
      "ISO_CODES": "PS / PSE"
  },
  {
      "COUNTRY": "United Arab Emirates",
      "COUNTRY_CODE": "+971",
      "ISO_CODES": "AE / ARE"
  },
  {
      "COUNTRY": "Israel",
      "COUNTRY_CODE": "+972",
      "ISO_CODES": "IL / ISR"
  },
  {
      "COUNTRY": "Bahrain",
      "COUNTRY_CODE": "+973",
      "ISO_CODES": "BH / BHR"
  },
  {
      "COUNTRY": "Qatar",
      "COUNTRY_CODE": "+974",
      "ISO_CODES": "QA / QAT"
  },
  {
      "COUNTRY": "Bhutan",
      "COUNTRY_CODE": "+975",
      "ISO_CODES": "BT / BTN"
  },
  {
      "COUNTRY": "Mongolia",
      "COUNTRY_CODE": "+976",
      "ISO_CODES": "MN / MNG"
  },
  {
      "COUNTRY": "Nepal",
      "COUNTRY_CODE": "+977",
      "ISO_CODES": "NP / NPL"
  },
  {
      "COUNTRY": "Tajikistan",
      "COUNTRY_CODE": "+992",
      "ISO_CODES": "TJ / TJK"
  },
  {
      "COUNTRY": "Turkmenistan",
      "COUNTRY_CODE": "+993",
      "ISO_CODES": "TM / TKM"
  },
  {
      "COUNTRY": "Azerbaijan",
      "COUNTRY_CODE": "+994",
      "ISO_CODES": "AZ / AZE"
  },
  {
      "COUNTRY": "Georgia",
      "COUNTRY_CODE": "+995",
      "ISO_CODES": "GE / GEO"
  },
  {
      "COUNTRY": "Kyrgyzstan",
      "COUNTRY_CODE": "+996",
      "ISO_CODES": "KG / KGZ"
  },
  {
      "COUNTRY": "Uzbekistan",
      "COUNTRY_CODE": "+998",
      "ISO_CODES": "UZ / UZB"
  },
  {
      "COUNTRY": "Bahamas",
      "COUNTRY_CODE": "+1-242",
      "ISO_CODES": "BS / BHS"
  },
  {
      "COUNTRY": "Barbados",
      "COUNTRY_CODE": "+1-246",
      "ISO_CODES": "BB / BRB"
  },
  {
      "COUNTRY": "Anguilla",
      "COUNTRY_CODE": "+1-264",
      "ISO_CODES": "AI / AIA"
  },
  {
      "COUNTRY": "Antigua and Barbuda",
      "COUNTRY_CODE": "+1-268",
      "ISO_CODES": "AG / ATG"
  },
  {
      "COUNTRY": "British Virgin Islands",
      "COUNTRY_CODE": "+1-284",
      "ISO_CODES": "VG / VGB"
  },
  {
      "COUNTRY": "U.S. Virgin Islands",
      "COUNTRY_CODE": "+1-340",
      "ISO_CODES": "VI / VIR"
  },
  {
      "COUNTRY": "Cayman Islands",
      "COUNTRY_CODE": "+1-345",
      "ISO_CODES": "KY / CYM"
  },
  {
      "COUNTRY": "Bermuda",
      "COUNTRY_CODE": "+1-441",
      "ISO_CODES": "BM / BMU"
  },
  {
      "COUNTRY": "Grenada",
      "COUNTRY_CODE": "+1-473",
      "ISO_CODES": "GD / GRD"
  },
  {
      "COUNTRY": "Turks and Caicos Islands",
      "COUNTRY_CODE": "+1-649",
      "ISO_CODES": "TC / TCA"
  },
  {
      "COUNTRY": "Montserrat",
      "COUNTRY_CODE": "+1-664",
      "ISO_CODES": "MS / MSR"
  },
  {
      "COUNTRY": "Northern Mariana Islands",
      "COUNTRY_CODE": "+1-670",
      "ISO_CODES": "MP / MNP"
  },
  {
      "COUNTRY": "Guam",
      "COUNTRY_CODE": "+1-671",
      "ISO_CODES": "GU / GUM"
  },
  {
      "COUNTRY": "American Samoa",
      "COUNTRY_CODE": "+1-684",
      "ISO_CODES": "AS / ASM"
  },
  {
      "COUNTRY": "Sint Maarten",
      "COUNTRY_CODE": "+1-721",
      "ISO_CODES": "SX / SXM"
  },
  {
      "COUNTRY": "Saint Lucia",
      "COUNTRY_CODE": "+1-758",
      "ISO_CODES": "LC / LCA"
  },
  {
      "COUNTRY": "Dominica",
      "COUNTRY_CODE": "+1-767",
      "ISO_CODES": "DM / DMA"
  },
  {
      "COUNTRY": "Saint Vincent and the Grenadines",
      "COUNTRY_CODE": "+1-784",
      "ISO_CODES": "VC / VCT"
  },
  {
      "COUNTRY": "Puerto Rico",
      "COUNTRY_CODE": "+1-787",
      "ISO_CODES": "PR / PRI"
  },
  {
    "COUNTRY": "Puerto Rico",
    "COUNTRY_CODE": "+1-939",
    "ISO_CODES": "PR / PRI"
  },
  {
      "COUNTRY": "Dominican Republic",
      "COUNTRY_CODE": "+1-809",
      "ISO_CODES": "DO / DOM"
  },
  {
    "COUNTRY": "Dominican Republic",
    "COUNTRY_CODE": "+1-829",
    "ISO_CODES": "DO / DOM"
  },
  {
    "COUNTRY": "Dominican Republic",
    "COUNTRY_CODE": "+1-849",
    "ISO_CODES": "DO / DOM"
  },
  {
      "COUNTRY": "Trinidad and Tobago",
      "COUNTRY_CODE": "+1-868",
      "ISO_CODES": "TT / TTO"
  },
  {
      "COUNTRY": "Saint Kitts and Nevis",
      "COUNTRY_CODE": "+1-869",
      "ISO_CODES": "KN / KNA"
  },
  {
      "COUNTRY": "Jamaica",
      "COUNTRY_CODE": "+1-876",
      "ISO_CODES": "JM / JAM"
  },
  {
      "COUNTRY": "Guernsey",
      "COUNTRY_CODE": "+44-1481",
      "ISO_CODES": "GG / GGY"
  },
  {
      "COUNTRY": "Jersey",
      "COUNTRY_CODE": "+44-1534",
      "ISO_CODES": "JE / JEY"
  },
  {
      "COUNTRY": "Isle of Man",
      "COUNTRY_CODE": "+44-1624",
      "ISO_CODES": "IM / IMN"
  }
]

const flightCollapse = {
  Departure: 'departure',
  Return: 'return',
  Hotel: 'hotel'
}

const forgotPasswordStep = {
  inputEmail: 'input-email',
  inputNewPassword: 'input-new-password',
  complete: 'complete'
}

const forgotPasswordEnum = [
  {
    title: <FormattedMessage
      id="ForgotPasswordEnum.InputEmail"
      defaultMessage='Nhập Email'
    />,
    value: forgotPasswordStep.inputEmail
  },
  {
    title: <FormattedMessage
      id="ForgotPasswordEnum.ResetPassword"
      defaultMessage='Đặt lại mật khẩu'
    />,
    value: forgotPasswordStep.inputNewPassword
  },
  {
    title: <FormattedMessage
      id="ForgotPasswordEnum.Complete"
      defaultMessage='Hoàn thành'
    />,
    value: forgotPasswordStep.complete
  }
]

// REGISTER STEP
const registerAccountStep = {
  register: 'register',
  verifyEmail: 'verify-email'
}

const registerAccountEnum = [
  {
    title: <FormattedMessage
      id="RegisterAccountEnum.Register"
      defaultMessage='Đăng ký'
    />,
    value: registerAccountStep.register
  },
  {
    title: <FormattedMessage
      id="RegisterAccountEnum.Verify"
      defaultMessage='Xác thực'
    />,
    value: registerAccountStep.verifyEmail
  }
]

// COMBO STEP
const comboStep = {
  FindHotel: 'find-hotel',
  FindFlight: 'find-flight',
  Cart: 'cart',
  FillForm: 'fill-form',
  Payment: 'payment',
  Complete: 'complete'
}

const comboStepEnum = [
  {
    title: <FormattedMessage
      id="Combo.Step.Cart"
      defaultMessage='Tổng kết hành trình'
    />,
    value: comboStep.Cart
  },
  {
    title: <FormattedMessage
      id="Combo.Step.FillForm"
      defaultMessage='Điền thông tin'
    />,
    value: comboStep.FillForm
  },
  {
    title: <FormattedMessage
      id="Combo.Step.Payment"
      defaultMessage='Thanh toán'
    />,
    value: comboStep.Payment
  },
  {
    title: <FormattedMessage
      id="Combo.Step.Complete"
      defaultMessage='Hoàn thành'
    />,
    value: comboStep.Complete
  }
]

// TYPE OF STAY
// const typeStay = {
//   Resort: 'Resort',
//   Villa: 'Villa',
//   Condotel: 'Condotel',
//   Apartment: 'Apartment',
//   NotSpecified: 'NotSpecified',
// }

const typeStay = {
  HotelRoom: 'HotelRoom',
  Villa2Bedroom: 'Villa2Bedroom',
  Villa3Bedroom: 'Villa3Bedroom',
  Villa4Bedroom: 'Villa4Bedroom',
  Condotel1Bedroom: 'Condotel1Bedroom',
  Condotel2Bedroom: 'Condotel2Bedroom',
  NotSpecified: 'NotSpecified',
}

const typeStayEnum = [
  {
    value: typeStay.HotelRoom,
    label: <FormattedMessage
      id="Hotel.Search.Filter.HotelRoom"
      defaultMessage="Phòng khách sạn"
    />
  },
  {
    value: typeStay.Villa2Bedroom,
    label: <FormattedMessage
      id="Filter.Villa2Bedroom"
      defaultMessage='Villa 2PN'
    />
  },
  {
    value: typeStay.Villa3Bedroom,
    label: <FormattedMessage
      id="Filter.Villa3Bedroom"
      defaultMessage='Villa 3PN'
    />
  },
  {
    value: typeStay.Villa4Bedroom,
    label: <FormattedMessage
      id="Filter.Villa4Bedroom"
      defaultMessage='Villa 4PN'
    />
  },
  {
    value: typeStay.Condotel1Bedroom,
    label: <FormattedMessage
      id="Filter.Condotel1Bedroom"
      defaultMessage='Condotel 1PN'
    />
  },
  {
    value: typeStay.Condotel2Bedroom,
    label: <FormattedMessage
      id="Filter.Condotel2Bedroom"
      defaultMessage='Condotel 2PN'
    />
  },
  {
    value: typeStay.NotSpecified,
    label: <FormattedMessage
      id="Filter.Others"
      defaultMessage='Khác'
    />
  }
]

// const typeStayEnum = [
//   {
//     value: typeStay.Resort,
//     label: 'Resort'
//   },
//   {
//     value: typeStay.Villa,
//     label: 'Villa'
//   },
//   {
//     value: typeStay.Condotel,
//     label: 'Condotel'
//   },
//   {
//     value: typeStay.Apartment,
//     label: 'Apartment'
//   },
//   {
//     value: typeStay.NotSpecified,
//     label: <FormattedMessage
//       id="Filter.Others"
//       defaultMessage='Khác'
//     />
//   }
// ]

//PRIORITY RESTING PLACE
const priorityRestingPlace = {
  seaView: 'seaView',
  towardsTheSea: 'towardsTheSea',
  family: 'family',
  specialOffer: 'specialOffer'
}
const priorityRestingPlaceEnum = [
  {
    value: priorityRestingPlace.seaView,
    label: <FormattedMessage
      id="Filter.SeaViews"
      defaultMessage='Nhìn ra biển'
    />
  },
  {
    value: priorityRestingPlace.towardsTheSea,
    label: <FormattedMessage
      id="Filter.TowardsTheSea"
      defaultMessage='Hướng biển'
    />
  },
  {
    value: priorityRestingPlace.family,
    label: <FormattedMessage
      id="Filter.Family"
      defaultMessage='Phù hợp gia đình'
    />
  },
  {
    value: priorityRestingPlace.specialOffer,
    label: <FormattedMessage
      id="Filter.SpecialOffer"
      defaultMessage='Ưu đãi đặc biệt'
    />
  }
]

// condition filter
const priceConditionFilter = {
  minPrice: 'minPrice',
  maxPrice: 'maxPrice'
}
const priceConditionFilterEnum = [

  {
    value: sortType.NoSort,
    label: <FormattedMessage
      id="Hotel.Search.Filter.NoSort"
      defaultMessage="Gợi ý từ Vinpearl"
    />
  },
  {
    value: sortType.MinPrice,
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceLowHigh"
      defaultMessage='Giá thấp đến cao'
    />
  },
  {
    value: sortType.MaxPrice,
    label: <FormattedMessage
      id="Voucher.Search.Filter.PriceHighLow"
      defaultMessage='Giá cao đến thấp'
    />
  }
]

const tourProductType = {
  VoucherFlightHotel: 1,
  VoucherHotel: 2,
  TourExperience: 3,
  AirportPickUp: 4,
  InstallmentVin: 5,
  HighSpeedShipHotel: 6,
}
const tourProductTypeEnum = [
  {
    value: tourProductType.VoucherFlightHotel,
    name: <FormattedMessage
      id="Tour.Search.Filter.VoucherFlightHotel"
      defaultMessage="Vé máy bay + KS Vinpearl"
    />
  },
  {
    value: tourProductType.HighSpeedShipHotel,
    name: <FormattedMessage
      id="Tour.Search.Filter.HighSpeedShipHotel"
      defaultMessage='Tàu cao tốc + KS Vinpearl'
    />
  },
  {
    value: tourProductType.VoucherHotel,
    name: <FormattedMessage
      id="Tour.Search.Filter.VoucherHotel"
      defaultMessage="Gói phòng Vinpearl"
    />
  },
  {
    value: tourProductType.TourExperience,
    name: <FormattedMessage
      id="Tour.Search.Filter.TourExperience"
      defaultMessage="Tour & Trải nghiệm"
    />
  },
  {
    value: tourProductType.AirportPickUp,
    name: <FormattedMessage
      id="Tour.Search.Filter.AirportPickUp"
      defaultMessage="Đón tiễn sân bay"
    />
  },
  {
    value: tourProductType.InstallmentVin,
    name: <FormattedMessage
      id="PaymentMethod.Installment.Fee.Vin"
      defaultMessage='Trả góp 0%'
    />
  },
]

const orderStatusCode = {
  NEW: 1,
  CHECKOUT_WAITING_PAYMENT: 2,
  PAID: 3
}

const apiErrorCode = {
  UNDEFINED_ERROR: 'UNDEFINED_ERROR',

  AIRLINE_EXISTED: 'AIRLINE_EXISTED',
  AIRLINE_NOT_FOUNR: 'AIRLINE_NOT_FOUNR',
  INVALID_PASSENGER: 'INVALID_PASSENGER',
  INVALID_ADULT: 'INVALID_ADULT',
  INVALID_CHILD: 'INVALID_CHILD',
  INVALID_INF: 'INVALID_INF',
  INVALID_START_POINT: 'INVALID_START_POINT',
  INVALID_END_POINT: 'INVALID_END_POINT',
  INVALID_ITINERARY: 'INVALID_ITINERARY',
  INVALID_ITINERARY_TYPE: 'INVALID_ITINERARY_TYPE',
  INVALID_DEPARTURE_DATE: 'INVALID_DEPARTURE_DATE',
  INVALID_RETURN_DATE: 'INVALID_RETURN_DATE',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  FLIGHT_SESSION_NOT_FOUND: 'FLIGHT_SESSION_NOT_FOUND',
  FLIGHT_NOT_FOUND: 'FLIGHT_NOT_FOUND',
  AIRPORT_NOT_FOUND: 'AIRPORT_NOT_FOUND',
  COMMIT_BOOKING_FAILED: 'COMMIT_BOOKING_FAILED',
  MRU_BUNDLE_PRICES_HAD_CHANGED: 'MRU_BUNDLE_PRICES_HAD_CHANGED',


  // auth
  ID_USER_EXISTED: 'ID_USER_EXISTED',
  ID_USER_NOT_FOUND: 'ID_USER_NOT_FOUND',
  ID_USER_NOT_FOUND_OR_LOCKED: 'ID_USER_NOT_FOUND_OR_LOCKED',
  ID_USER_LOCKED: 'ID_USER_LOCKED',
  ID_USER_LOGIN_NOT_ALLOW: 'ID_USER_LOGIN_NOT_ALLOW',
  ID_USER_LOCK_WARNING: 'ID_USER_LOCK_WARNING',
  ID_USER_EMAIL_NOT_CONFIRM: 'ID_USER_EMAIL_NOT_CONFIRM',
  UNCONFIRMED_EMAIL: 'UNCONFIRMED_EMAIL',
  ID_TOKEN_EXPIRED: 'ID_TOKEN_EXPIRED',
  ID_WRONG_RESET_CODE: 'ID_WRONG_RESET_CODE',
  ID_WRONG_OLD_PASSWORD: 'ID_WRONG_OLD_PASSWORD',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  ID_TOKEN_INVALID: 'ID_TOKEN_INVALID',
  INVALID_TOKEN: 'INVALID_TOKEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  SAME_PASSWORD: 'SAME_PASSWORD',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  LOGIN_FAILED: 'LOGIN_FAILED',
  SEND_EMAIL_RESET_PASSWORD_SUCCESS: 'SEND_EMAIL_RESET_PASSWORD_SUCCESS',
  invalid_grant: 'invalid_grant',
  too_many_attempts: 'too_many_attempts',
  invalid_signup: 'invalid_signup',
  invalid_password: 'invalid_password',
  EXISTED_USERNAME: 'EXISTED_USERNAME',
  REQUEST_INVALID: '1001',
  INVALID_EMAIL_OR_PHONE: '1000', 
  ID_GET_PROFILE_FAIL: 'ID_GET_PROFILE_FAIL',

  //voucher+ tour
  QUANTITY_LIMIT: 'QUANTITY_LIMIT',
  TOUR_QUANTITY_NOT_ENOUGH: 'TOUR_QUANTITY_NOT_ENOUGH',
  TOUR_EXPIRED: 'TOUR_EXPIRED',
  VOUCHER_LOCKED: 'VOUCHER_LOCKED',
  VOUCHER_WAS_EXPIRED: 'VOUCHER_WAS_EXPIRED',
  NOT_ENOUGH_VOUCHER: 'NOT_ENOUGH_VOUCHER',
  VOUCHER_QUANTITY_NOT_ENOUGH: 'VOUCHER_QUANTITY_NOT_ENOUGH',
  QUANTITY_MUST_BE_MULTIPLE_OF_TWO: 'QUANTITY_MUST_BE_MULTIPLE_OF_TWO',

  //Coupon
  COUPON_NOT_FOUND: 'COUPON_NOT_FOUND',
  LOYALTY_NOT_ENOUGH_QUANTITY: 'LOYALTY_NOT_ENOUGH_QUANTITY',
  PROMOTION_NOT_ENOUGH_QUANTITY: 'PROMOTION_NOT_ENOUGH_QUANTITY',
  COUPON_NOT_STARTED: 'COUPON_NOT_STARTED',
  COUPON_EXPIRED: 'COUPON_EXPIRED',
  COUPON_NOT_APPLY_TO_PRODUCT: 'COUPON_NOT_APPLY_TO_PRODUCT',
  COUPON_USED_UP: 'COUPON_USED_UP',
  HAS_ITEM_NOT_BE_APPLIED: 'HAS_ITEM_NOT_BE_APPLIED',
  NOT_ALLOW_TO_APPLY_PROMOTION_AND_INSTALLMEMT: 'NOT_ALLOW_TO_APPLY_PROMOTION_AND_INSTALLMEMT',
  OUT_OF_FREE_NINGHT_STAY: 'OUT_OF_FREE_NINGHT_STAY',
  TIME_FOR_USEDATE_FAILED: 'TIME_FOR_USEDATE_FAILED',
  HAVE_NOT_PROMOTIONAL_ITEM: 'HAVE_NOT_PROMOTIONAL_ITEM',
  OUT_OF_USE: 'OUT_OF_USE',
  INVALID_MINIMUM_ORDER_AMOUNT: 'INVALID_MINIMUM_ORDER_AMOUNT',
  PROMOTION_SIGNIN_REQUIRED: 'PROMOTION_SIGNIN_REQUIRED',
  CUTOFF_DATE_VALIDATE_FAIL: 'CUTOFF_DATE_VALIDATE_FAIL',
  INVALID_SCHEDULE: 'INVALID_SCHEDULE',

  //promotion cart error
  INVALID_SCHEDULE: 'INVALID_SCHEDULE',
  PROMOTION_NOT_APPLIED_FOR_USER: 'PROMOTION_NOT_APPLIED_FOR_USER',
  PROMOTION_EXPIRED: 'PROMOTION_EXPIRED',
  PROMOTION_USED: 'PROMOTION_USED',
  APPLY_CODE_FAIL: 'APPLY_CODE_FAIL',
  PROMOTION_INVALID_CONDITION: 'PROMOTION_INVALID_CONDITION',
  PROMOTION_CODE_IS_INVALID: 'PROMOTION_CODE_IS_INVALID',
  PROMOTION_CODE_NOT_EXISTED: 'PROMOTION_CODE_NOT_EXISTED',
  PROMOTION_CODE_NOT_FOUND: 'PROMOTION_CODE_NOT_FOUND',

  // Update profile
  SALESFORCE_API_UPSERT_ERROR: 'SALESFORCE_API_UPSERT_ERROR',

  //cart
  CART_WAS_PAID: 'CART_WAS_PAID',
  ITEM_NOT_APPLY_INSTALLMENT: 'ITEM_NOT_APPLY_INSTALLMENT',
  ONLY_APPLY_INSTALLMENT_FOR_ONE_PRODUCT: 'ONLY_APPLY_INSTALLMENT_FOR_ONE_PRODUCT',
  MIN_INSTALLMENT_ORDER_VALUE: 'MIN_INSTALLMENT_ORDER_IS_5_MILLION',
  CART_ITEM_NOT_FOUND: 'CART_ITEM_NOT_FOUND',
  ITEM_DISABLE_SELL: 'ITEM_DISABLE_SELL',
  INVALID_QUANTITY: 'INVALID_QUANTITY',
  GET_EXCHANGE_POINT_FAIL: 'GET_EXCHANGE_POINT_FAIL',
  ORDER_FAILED: 'ORDER_FAILED',
  ORDER_NOT_MULTIPLE_SITECODE: 'ORDER_NOT_MULTIPLE_SITECODE',

  //PHS
  CREATE_PHS_ERROR: 'CREATE_PHS_ERROR',
  PHS_SESSION_EXPIRED: 'PHS_SESSION_EXPIRED',
  PHS_SESSION_NOT_VALID: 'PHS_SESSION_NOT_VALID',

  // hotel
  PROMOTION_INVALID: 'PROMOTION_INVALID',
  INVALID_LENGTH_OF_STAY: 'INVALID_LENGTH_OF_STAY',
  AUTH_VERIFY_ERROR: 'VERIFY_EMAIL_ERROR',
  HAVENOT_ROOM: 'HAVENOT_ROOM',
  PROMOTION_CODE_NOT_FOUND: 'PROMOTION_CODE_NOT_FOUND',
  PROMOTION_INVALID_SEARCH_CONDITION: 'PROMOTION_INVALID_SEARCH_CONDITION',
  MKR_PROMOTION_CODE_NOT_MATCH_ANY_MARKUP_CAMPAIGN: 'MKR_PROMOTION_CODE_NOT_MATCH_ANY_MARKUP_CAMPAIGN',
  MKR_PROMOTION_HOTEL_REQUEST_CONDITIONS_INVALID: 'MKR_PROMOTION_HOTEL_REQUEST_CONDITIONS_INVALID',
  MKR_PROMOTION_CHECK_CODE_MISSING_HOTEL_IDS: 'MKR_PROMOTION_CHECK_CODE_MISSING_HOTEL_IDS',
  MKR_PROMOTION_APPLY_CODE_MISSING_RATEPLAN_IDS: 'MKR_PROMOTION_APPLY_CODE_MISSING_RATEPLAN_IDS',
  MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST: 'MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST',
  MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST: 'MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST',
  CHECK_PROMOTION_CODE_FAILED: 'CHECK_PROMOTION_CODE_FAILED',
  PRICES_HAD_CHANGED: 'PRICES_HAD_CHANGED',
  INVALID_CHANNEL: 'INVALID_CHANNEL',
  // auth0
  AUTH0_LOGIN_REQUIRED: 'login_required',
  CHECKOUT_DATE_GEATER_THAN_CARD_EXPIRED_DATE: 'CHECKOUT_DATE_GEATER_THAN_CARD_EXPIRED_DATE',
  ARRIVAL_DATE_INVALID: 'ARRIVAL_DATE_INVALID',
  FLIGHT_HOLDING_TIMEOUT: 'FLIGHT_HOLDING_TIMEOUT',
  //Credit
  SYS_RECHECK_CREDIT_FAILED: 'SYS_RECHECK_CREDIT_FAILED',
  SYS_CRD_INVALID_PENDING_ORDER: 'SYS_CRD_INVALID_PENDING_ORDER',
  SYS_INVALID_PARAM_CREDIT_REQUESTS: 'SYS_INVALID_PARAM_CREDIT_REQUESTS',
  SYS_CRD_CHECK_EXCHANGE_POINT_FAILED: 'SYS_CRD_CHECK_EXCHANGE_POINT_FAILED',
  SYS_CRD_USER_NOT_FOUND: 'SYS_CRD_USER_NOT_FOUND',
  SYS_CRD_INVALID_PARAMS: 'SYS_CRD_INVALID_PARAMS',
  INVALID_MULTIPLE: 'INVALID_MULTIPLE',
  DUPLICATE_INFORMATION: 'DUPLICATE_INFORMATION',

  // CrossSell
  ORDER_WAS_OUT_OF_DATE: 'ORDER_WAS_OUT_OF_DATE',
  ORDER_NOT_PAID_OR_SUCCESS: 'ORDER_NOT_PAID_OR_SUCCESS',
  ORDER_IS_NOT_HOTEL: 'ORDER_IS_NOT_HOTEL',
  INVALID_PARAMS: 'INVALID_PARAMS',
  NOT_FOUND: 'NOT_FOUND',
  CANNOT_GET_RESERVATION_INFO: 'CANNOT_GET_RESERVATION_INFO',
  PROMOTION_NOT_ALLOW_LINK_CROSS_SALE: 'PROMOTION_NOT_ALLOW_LINK_CROSS_SALE',
  INVALID_ORDER_ITEM_TYPE: 'INVALID_ORDER_ITEM_TYPE',
  PRICES_HAD_CHANGED: 'PRICES_HAD_CHANGED'
}

const BOOKING_TYPE = {
  VOUCHER: 1,
  TOUR: 2,
  FLIGHT: 3,
  HOTEL: 4,
  VINWONDER: 5,
  BUNDLE: 6,
  VINWONDER_NEW: 7,
  PACKAGERATE: 8,
  CROSSSELL: 9,
  STAYNFUN: 10,
}

export const productTypeEnumMapping = {
  1: 'VOUCHER',
  2: 'TOUR',
  3: 'FLIGHT',
  4: 'HOTEL',
  5: 'VINWONDER',
  7: 'TOUR'
}

export const routingTypeEnumMapping = {
  VOUCHER: routes.VOUCHER_DETAIL,
  TOUR: routes.TOUR_DETAIL,
  VINWONDER: routes.EXPERIENCE_DETAIL,
}

export const routingWithSlugTypeEnumMapping = {
  VOUCHER: 'VOUCHER_DETAIL_SLUG',
  TOUR: 'TOUR_DETAIL_SLUG',
  VINWONDER: 'EXPERIENCE_DETAIL_SLUG',
}


export const COUPON = {
  DISCOUNT_TYPE: {
    PERCENT: 'percent',
    MONEY: 'money'
  },
  PROMOTION_DISCONT_TYPE: {
    PERCENTAGE: 'percent',
    MONEY: 'money'
  },
  PROMOTION_MARKUP_DISCONT_TYPE: {
    PERCENTAGE: 'PERCENTAGE',
    MONEY: 'MONEY'
  }
}

export const PAYMENT_GATEWAY = {
  TYPE: {
    VNPAY: 'vnpay'
  }
}

const taxFee = {
  Exclusive: 'Exclusive',
  Inclusive: 'Inclusive'
}

const PROFILE_TYPE = {
  Guest: 'Guest',
  Booker: 'Booker'
}

const locationType = {
  Hotel: 'Hotel',
  Location: 'Location'
}

const locationCode = {
  Location: 'location',
  Destination: 'destination'
}

const voucherPriceRange = {
  All: {
    value: 'all',
    minPrice: null,
    maxPrice: null
  },
  FirstRange: {
    value: '0-5',
    maxPrice: 5000000
  },
  SecondRange: {
    value: '5-10',
    minPrice: 5000000,
    maxPrice: 10000000
  },
  ThirdRange: {
    value: '10-20',
    minPrice: 10000000,
    maxPrice: 20000000
  },
  FourthRange: {
    value: '20-30',
    minPrice: 20000000,
    maxPrice: 30000000
  },
  FifthRange: {
    value: '30-50',
    minPrice: 30000000,
    maxPrice: 50000000
  },
  SixthRange: {
    value: '50+',
    minPrice: 50000000
  }
}

const voucherPriceRangeEnum = [
  {
    ...voucherPriceRange.All,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.All"
      defaultMessage={'Tất cả'}
    />
  },
  {
    ...voucherPriceRange.FirstRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.SmallerThan"
      defaultMessage={`Dưới {number}đ`}
      values={{
        number: <FormattedNumber value={voucherPriceRange.FirstRange.maxPrice} />
      }}
    />
  },
  {
    ...voucherPriceRange.SecondRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.NormalRange"
      defaultMessage={`Từ {from}đ đến {to}đ`}
      values={{
        from: <FormattedNumber value={voucherPriceRange.SecondRange.minPrice} />,
        to: <FormattedNumber value={voucherPriceRange.SecondRange.maxPrice} />
      }}
    />
  },
  {
    ...voucherPriceRange.ThirdRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.NormalRange"
      defaultMessage={`Từ {from}đ đến {to}đ`}
      values={{
        from: <FormattedNumber value={voucherPriceRange.ThirdRange.minPrice} />,
        to: <FormattedNumber value={voucherPriceRange.ThirdRange.maxPrice} />
      }}
    />
  },
  {
    ...voucherPriceRange.FourthRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.NormalRange"
      defaultMessage={`Từ {from}đ đến {to}đ`}
      values={{
        from: <FormattedNumber value={voucherPriceRange.FourthRange.minPrice} />,
        to: <FormattedNumber value={voucherPriceRange.FourthRange.maxPrice} />
      }}
    />
  },
  {
    ...voucherPriceRange.FifthRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.NormalRange"
      defaultMessage={`Từ {from}đ đến {to}đ`}
      values={{
        from: <FormattedNumber value={voucherPriceRange.FifthRange.minPrice} />,
        to: <FormattedNumber value={voucherPriceRange.FifthRange.maxPrice} />
      }}
    />
  },
  {
    ...voucherPriceRange.SixthRange,
    label: <FormattedMessage
      id="Enum.VoucherPriceRange.MoreThan"
      defaultMessage={`Trên {number}`}
      values={{
        number: <FormattedNumber value={voucherPriceRange.SixthRange.minPrice} />,
      }}
    />
  }
]

const cartStep = {
  FillForm: 'FillForm',
  Complete: 'Complete'
}

const cartStepEnum = [
  {
    value: cartStep.FillForm,
    title: <FormattedMessage
      id="Enum.CartStep.FillForm"
      defaultMessage='Điền thông tin'
    />
  },
  {
    value: cartStep.Complete,
    title: <FormattedMessage
      id="Enum.CartStep.Complete"
      defaultMessage='Hoàn thành'
    />
  }
]

const tourTicketType = {
  Adult: 'adult',
  Child: 'child'
}

const tourTicketTypeEnum = [
  {
    value: tourTicketType.Adult,
    name: <FormattedMessage
      id="Tour.Detail.TicketType.Name.adult"
      defaultMessage="Người lớn"
    />,
    description: <FormattedMessage
      id="Tour.Detail.TicketType.Description.adult"
      defaultMessage="từ 12 tuổi trở lên"
    />
  },
  {
    value: tourTicketType.Child,
    name: <FormattedMessage
      id="Tour.Detail.TicketType.Name.child"
      defaultMessage="Trẻ em"
    />,
    description: <FormattedMessage
      id="Tour.Detail.TicketType.Description.child"
      defaultMessage="từ 02 tuổi đến dưới 12 tuổi"
    />
  },
]

const comboCart = {
  SESSION_TIME: 15
}

const comboErrors = {
  COMMIT_BOOKING_FAILED: 'combo_booking_fail',
}

const matchingLocaleData = {
  [country.Vietnamese]: {
    language: language.Vietnamese,
    currency: currencyUnit.VietnameseDong,
  },
  [country.English]: {
    language: language.English,
    currency: currencyUnit.Dollar,
  },
  [country.Korean]: {
    language: language.Korean,
    currency: currencyUnit.Won,
  },
}

const lengthOfTour = {
  TwoDaysOneNight: 2.1,
  ThreeDaysTwoNights: 3.2,
  FourDaysThreeNights: 4.3,
  FiveDaysFourNights: 5.4,
  SixDaysFiveNights: 6.5,
  SixDaysFourNights: 6.4,
}

const lengthOfTourEnum = [
  {
    value: lengthOfTour.TwoDaysOneNight,
    name: <FormattedMessage
      id="Filter.LengthOfTour.TwoDayOneNight"
      defaultMessage='2 ngày 1 đêm'
    />
  },
  {
    value: lengthOfTour.ThreeDaysTwoNights,
    name: <FormattedMessage
      id="Filter.LengthOfTour.ThreeDaysTwoNights"
      defaultMessage='3 ngày 2 đêm'
    />
  },
  {
    value: lengthOfTour.FourDaysThreeNights,
    name: <FormattedMessage
      id="Filter.LengthOfTour.FourDaysThreeNights"
      defaultMessage='4 ngày 3 đêm'
    />
  },
  {
    value: lengthOfTour.FiveDaysFourNights,
    name: <FormattedMessage
      id="Filter.LengthOfTour.FiveDaysFourNights"
      defaultMessage='5 ngày 4 đêm'
    />
  },
  {
    value: lengthOfTour.SixDaysFiveNights,
    name: <FormattedMessage
      id="Filter.LengthOfTour.SixDaysFiveNights"
      defaultMessage='6 ngày 5 đêm'
    />
  },
  {
    value: lengthOfTour.SixDaysFourNights,
    name: <FormattedMessage
      id="Filter.LengthOfTour.SixDaysFourNights"
      defaultMessage='6 ngày 4 đêm'
    />
  },
]

export const addToCartSuccess = <FormattedMessage
  id="AddToCartSuccessfully"
  defaultMessage='Thêm vào giỏ hàng thành công'
/>

export const defaultMessageTicketCart = {
  quantity: 'Người lớn x {value}',
  childQuantity: 'Trẻ em x {value}',
  seniorQuantity: 'Người cao tuổi x {value}'
}

export const tagGroupProductType = {
  tourAndExperience: 1,
  hotel: 2
}

export const tagGroupType = {
  filter: 1,
  tag: 2
}


export const crossCheck = {
  YES: {value: 1, text: "YES"},
  NO: {value: 0, text: "NO"},
  ALTER: {value: 2, text: "ALTER"},
}

export const mappingMembershipPromotion = {
  HOTEL: {
    ELITE: '',
    DIAMOND: '',
    GOLD: '',
    TEMP: 'MEMBER5',
    PEARL: 'PEARL5',
    PEARL_CLUB: 'VIPPEARL10',
    AMBASSADOR_PEARL: 'AMBPEARL5',
    Guest: "",
    LoggedIn: '',
  },
  TOUR_AND_EXPERIENCE: {
    ELITE: '',
    DIAMOND: '',
    GOLD: '',
    TEMP: '',
    PEARL: '',
    PEARL_CLUB: 'VIPPEARL5',
    AMBASSADOR_PEARL: '',
    Guest: '',
    LoggedIn: '',
  }
};

export const freeBaggageConditions = {
  airlineCode: 'VJ',
  value: '20',
  price: 0,
  currency: 'VND',
  startDate: '27/02/2021 00:00',
  endDate: '31/03/2021 23:45',
  departureDate: '27/02/2021 00:00',
  returnDate: '25/04/2021 23:59',
  groupClass: 'Eco',
}

const identityTypeEnum = [
  {
    key: "CMT",
    value: "CMT",
    label: <FormattedMessage
      id="User.FillForm.CMT"
      defaultMessage='Chứng Minh Thư'
    />
  },
  {
    key: "PASSPORT",
    value: "PASSPORT",
    label: <FormattedMessage
      id="User.FillForm.PASSPORT"
      defaultMessage='Passport'
    />
  },{
    key: "CCCD",
    value: "CCCD",
    label: <FormattedMessage
      id="User.FillForm.CCCD"
      defaultMessage='Căn Cước Công Dân'
    />
  },
  // {
  //   key: 3,
  //   value: 'TheQuanNhan',
  //   label: <FormattedMessage
  //     id="User.FillForm.TheQuanNhan"
  //     defaultMessage='Thẻ quân nhân'
  //   />
  // },
  // {
  //   key: 4,
  //   value: 'GKS',
  //   label: <FormattedMessage
  //     id="User.FillForm.GKS"
  //     defaultMessage='Giấy khai sinh'
  //   />
  // },
  // {
  //   key: 4,
  //   value: 'BLX',
  //   label: <FormattedMessage
  //     id="User.FillForm.BLX"
  //     defaultMessage='Bằng lái xe'
  //   />
  // }
]

export const OTP_TYPE = {
  SIGN_UP: 1,
  FORGOT_PASSWORD: 2,
  UPDATE: 3,
}

export const OTP_CHANNEL = {
  zalo: 1,
  whatsapp: 2,
  sms: 3,
  email: 4
}

export const SCOPE_TYPE = {
  EMAIL_NUMBER: 1,
  PHONE_NUMBER: 2,
  EMAIL_STRING: 'email',
  PHONE_STRING: 'phone',
}

export const chatbotVinpearlInfo = {
  agent: {
    name: 'VINPEARL',
    id: '5fdad6a848f0bbdfb0d320a9',
    vid: '60f527acf838cc4d4f91b413',
    cid: '6017759dcfa195716b48ac28',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjE5Mjc1MTAxNzMsImlhdCI6MTYxMjE1MDE3MywiaXNzIjoiNjAxNzc1OWRkMjc0MDI3MDVjNDIyMzQzIiwicHVibGljX2lkIjoiNWZkYWQ2YTg0OGYwYmJkZmIwZDMyMGE5IiwibmFtZSI6ImNoYXRib3QifQ.DJ7gU0p62V2YYZuN2i1fQZw0kojzYWZqEMdC3GCa9XYNe0igEqYJtc45UtnwPd8k_D73s7k9mQ9ume2DNUvjKnctgDv78t3hyCFmtYZVCLATyLaYcqLin0lvQEQL59JKR4gBO4nmuq5D9CfpT8A0KKm48JwG0AwXi5jVq7yN85mlTrqZl6Z6qXrJcuu_KQCw3GELqCdFEEMxy90yZe7nsP4tu4Sui2PCO2_qCsNQJ9pCz_x68I6gcC88sPNmeg70Nnj9RE9Nek82A77pftOCRZxE-5pwE0hM0cda_6HQwWerqkTXsiP_ix6zyAnQzryd26fj8h885Q4Yb-gngocgxA',
    display_name: 'undefined',
    bot_name: 'VINPEARL',
  },
  agent_mobile: {
    name: 'VINPEARL_MOBILE_WEB',
    id: '5fdad6a848f0bbdfb0d320a9',
    vid: '61ee0e7dd060d1e6f859a103',
    cid: '6273806e40d7a90070e38d87',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjE5NjcwOTY2ODYsImlhdCI6MTY1MTczNjY4NiwiaXNzIjoiNjI3MzgwNmVlOTI5MzUxZjA0NTE3ODY3IiwicHVibGljX2lkIjoiNWZkYWQ2YTg0OGYwYmJkZmIwZDMyMGE5IiwibmFtZSI6ImNoYXRib3QifQ.tAmx8Kg67g5rstv92zkJATrvu-FYF9_U1ZbDh6Zw9LpSoolMAO7qQigGKDHqw-GwY0tU0liCjGP7jjCbOpgLD5s5gaa9IgVVhlaPMjrUlQPQg2AMqAy2AfbYVSppNQqQrcv0MIKOb5pMceh8rUTxQw0Lm45BBBdpCnrQyiMUGXBkvh6gXqSD1kR0TfF2gm4mdINElW164Cd5SlRhk4Q8RWRuwud4lY4ZkNWGBJJV8gkqsGR7dUnVo3h1Pa4tY65entI0zkPdJjV0XKBAwboFvBEAx6kdLkmILCWBIE5b68W5O-Fbqkn474Rj2WASQTWgX21ePeFmFchoyV2nc-Xvsg',
    bot_name: 'VINPEARL',
  },
  baseUrl: 'https://vinbase.ai',
  ws_host: 'wss://vinbase.ai',

  widgetConfig: {
    // welcome_msg: <FormattedMessage
    //   id="Enum.Chatbot.WelcomeMsg"
    //   defaultMessage='Chào đón bạn đến với Vinpearl! Mình là Bot, thật tuyêt vời khi được hỗ trợ bạn! 😊'
    // />,
    welcome_msg: 'Chào đón {{username}} đến với Vinpearl! Tôi là Monta, thật tuyệt vời khi được hỗ trợ Quý khách!',
    introduce_msg: 'Xin chào 👋! Tôi là Monta - Trợ lý ảo của Vinpearl. Quý khách vui lòng cung cấp một số thông tin để Monta có thể hỗ  trợ, giải đáp về các dịch vụ và gói sản phẩm giúp Quý Khách nhé!\n',
    fields: [
      {"value":"name","placeholder":"Nhập Tên ","required":true,"enable":true},
      {"value":"email","placeholder":"Nhập Email","required":true,"enable":true},
      {"value":"phone","placeholder":"Nhập Số điện thoại","required":false,"enable":true}
    ],


    // introduce_msg: <FormattedMessage
    //   id="Enum.Chatbot.IntroduceMsg"
    //   defaultMessage='Xin chào👋! Mình là Trợ lý ảo của Vinpearl. Bạn hãy đặt câu hỏi về các sản phẩm của Vinpearl để được hỗ trợ nhé!'
    // />,
    header_color: '#C57B1C',
    header_text: '#FFFFFF',
    background_color: '#f2f5fC',
    bot_text_color: '#1A2948',
    bot_bubble_color: '#D1CECE',
    user_text_color: 'white',
    user_bubble_color: '#4080FF',
    avatar: 'https://vinbase.ai/api/v1/file/download/616501b231dc2538c96738e7',
    fb_icon: 'https://vinbase.ai/api/v1/file/download/6163a64d4af7b967b2c47df7',
    get_started_action_type: 'skill-trigger',
    get_started_action_payload: '$GREETING_POPUP_WEBSITE',
    use_server_config: true,
    open_automatically: 'false',
  }
}

const SITE_CODE = {
  VINKE: 1,
  TIMESCITY: 8,
}

export const airportDisplayType = {
  None: 0,
  Departure: 1,
  Arrival: 2,
  Both: 3
}

export const GENDER_MAP = {
  "male": "M",
  "female": "F",
  "other": "O"
};

export default {
  optionAll,
  passenger,
  IdType,
  vietNamCode,
  vietJetCode,
  passengerHotel,
  passengerType,

  stopover,
  stopoverEnum,

  airline,
  airlineEnum,

  takeOff,
  takeOffEnum,

  seatGroupClass,
  seatSelection,
  seatSelectionEnum,

  sortDirection,
  flightSort, flightSortEnum,

  voucherFilter,
  voucherTypes,
  filterPrices,
  tourFilter,
  paymentStep,
  paymentStepEnum,

  gender,
  gender3s,
  genderEnum,
  genderEnumForChild,
  genderEnum3s,
  genderEnumProfile,

  paymentMethod,
  paymentMethodEnum,

  cardType,
  cardTypeEnum,

  orderStatus,
  orderStatusCode,

  itineraryType,
  itineraryTypeEnum,

  prefixPhone,
  prefixPhoneEnum,
  prefixPhoneEnums,

  flightCollapse,

  forgotPasswordStep,
  forgotPasswordEnum,

  priorityRestingPlace,
  priorityRestingPlaceEnum,

  priceConditionFilter,
  priceConditionFilterEnum,

  registerAccountStep,
  registerAccountEnum,

  typeStay,
  typeStayEnum,

  apiErrorCode,

  BOOKING_TYPE: BOOKING_TYPE,

  comboStep,
  comboStepEnum,
  comboErrors,
  comboCart,

  taxFee,
  PROFILE_TYPE,
  locationType,
  locationCode,

  voucherPriceRange,
  voucherPriceRangeEnum,

  cartStep,
  cartStepEnum,

  sortType,

  matchingLocaleData,
  country,

  tourTicketType,
  tourTicketTypeEnum,

  lengthOfTour,
  lengthOfTourEnum,

  tourProductType,
  tourProductTypeEnum,

  addToCartSuccess,
  defaultMessageTicketCart,
  tagGroupProductType,
  tagGroupType,
  crossCheck,
  productTypeEnumMapping,
  routingWithSlugTypeEnumMapping,

  freeBaggageConditions,

  identityTypeEnum,
  chatbotVinpearlInfo,
  paymentType,
  SITE_CODE,
  OTP_TYPE,
  OTP_CHANNEL,
  SCOPE_TYPE,
}
