import actionTypes from '../actionTypes'
const initState = {
    interNationalNameByVietJet: [],
    loading: false
  }
  
  const vietjetReducer = (state = initState, action) => {
    switch (action.type) {
      case actionTypes.START_FETCHING_INTERNATIONAL_NAME:
        return Object.assign({}, state, {
            loading: true
        })
      case actionTypes.STOP_FETCHING_INTERNATIONAL_NAME:
        return Object.assign({}, state, {
            loading: false
        })
      case actionTypes.FETCHING_INTERNATIONAL_NAME_SUCCESS:
        return Object.assign({}, state, {
            interNationalNameByVietJet: action.payload.data
        })
      default:
        return state
    }
  }
  
  export default vietjetReducer