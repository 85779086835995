import axiosProvider, { axiosProviderCms, axiosProviderHotel, axiosProviderOrder } from './api/axiosProvider'
import actionTypes from '../actionTypes'

import {
  startFetchingAction,
  stopFetchingAction,
  loadSuccess,
  loadFail,
  startFetchingHotelAction, stopFetchingHotelAction,
  startFetchingHotelRoomAction, stopFetchingHotelRoomAction
} from './commonAction'
import { hotelServices } from '../services'
import { pushGtmDataLayerHotel } from '../utils/gtm'
import { convertLinkLocale, retryRequest } from '../utils/common'
import { cloneDeep, get, merge, orderBy, find, every, set, compact, isEqual } from 'lodash'
import { enumType, LOCAL_STORAGE_KEY, routes } from '../constants'
import queryString from '@utils/querystring'
import { message } from 'antd'
import { redirectPage } from '../hooks/common'
import { gtmUtils, hotelUtils } from '../utils'
import { queryStringExtensions } from '../extensions'
import Router from '@utils/router'
import { getConfig } from '@config'
//Utils
import { LocalStorage, SessionStorage } from '../utils'

// import mockAvailabilityRooms from "../mock/availabilityRooms.json";
// import mockHotelListing from "../mock/hotelListing.json";
// import mockHotelTaggroup from "../mock/hotelTaggroup.json";

const promiseLimit = require('p-limit');
let isLoaded = false
const {
  publicRuntimeConfig: {
    RECAPTCHA_KEY
  } // Available both client and server side
} = getConfig()

/**
 * Get hotel location
 * @param {*} keyword
 */
const getHotelLocations = (keyword, params = {}) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const keywordAll = enumType.optionAll;

      const response = await axiosProviderHotel.get(
        `/availability/locations/${keyword || keywordAll}`
      )
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes[keyword === keywordAll ? 'FETCHING_HOTEL_ALL_LOCATION_SUCCESS' : 'FETCHING_HOTEL_LOCATION_SUCCESS' ],
            response.data.locations
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingAction())
  }
}

const getHotelByLocations = (params) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const keywordAll = enumType.optionAll;

      const response = await axiosProviderHotel.get(
        `/availability/locations/type-locations`, { params: params }
      )
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_HOTELS_BY_LOCATION_SUCCESS,
            response.data.locations
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingAction())
  }
}

const getHotelsAsync = async (endpoint, params, currentHotelsData, setHotelsFn, checkIsCurrentHotel) => {
  let currentHotels = cloneDeep(currentHotelsData);
  let hotelIdsResp;
  try {
    hotelIdsResp = await axiosProviderHotel.post('/availability/hotels-list', params)
    if (get(hotelIdsResp, 'data.errors')) {
        return Promise.reject(hotelIdsResp)
    }
  } catch (errors) {
    return errors.response
  }

  
  const hotelIds = get(hotelIdsResp, 'data') || [];
  const callStack = [];
  const maxRunningStack = 4;
  const maxRequestRetry = 2;
  const limitRequest = promiseLimit(maxRunningStack);

  const requestItemThenFn = (resolve, reject) => response => {
    response.responseTime = Date.now();
    const reqParamsHotelId = JSON.parse(get(response, 'config.data') || '{}').hotelId
    if (!checkIsCurrentHotel()) {
      reject(response)
    } else if (get(response, 'data.hotelRates')) {
      const isTopHotel = params.topHotelId === reqParamsHotelId;
      const isSkipSetHotelState = !currentHotels.length && params.topHotelId && !isTopHotel;
      response.data.hotelRates = response.data.hotelRates
        .map(item => merge(item, { responseTime: response.responseTime }))
      currentHotels = currentHotels.concat(response.data.hotelRates);
      if (isSkipSetHotelState) {
        resolve(response)
      } else {
        setHotelsFn(currentHotels, () => resolve(response));
      }
    } else {
      resolve(response)
    }
  }
  const requestItemErrorHandle = (resolve) => responseError => resolve({ responseError });

  hotelIds.forEach((id) => {
    const hotelId = cloneDeep(id)
    const reqParams = cloneDeep(Object.assign(params, { hotelId, isHotelIdsSpecified: true }));
    callStack.push(
      limitRequest(() => new Promise((resolve, reject) => {
        retryRequest(
          maxRequestRetry,
          () => axiosProviderHotel.post(endpoint, reqParams),
          checkIsCurrentHotel
        ).then(requestItemThenFn(resolve, reject), requestItemErrorHandle(resolve))
      }))
    )
  });

  return new Promise((resolve, reject) => {
    Promise.all(callStack).then(responses => {
      if (!checkIsCurrentHotel()) {
        reject(response)
      } else if (every(responses, resp => !!resp.responseError)) {
        reject(get(responses, '[0].responseError'))
      } else {
        const resp = find(responses, item => item.status === 200);
        const rates = responses.reduce((rates, res) => rates.concat(get(res, 'data.hotelRates')), [])
        set(resp, 'data.hotelRates', orderBy(compact(rates), 'responseTime'))
        resolve(resp)
      }
    }).catch(reject)
  });
}

const getHotels = (data) => {
  return async (dispatch, getState) => {
    let hotelRates = [];

    const { flight, hotel } = hotelServices.getFlightAndHotelParams(data)
    const setParamsState = (type, params) => dispatch(loadSuccess(type, params))

    const isCurrentHotelParams = (params, getStateFn = getState) => () => {
      const { search } = getStateFn();
      return isEqual(search.hotel, params)
    }
    const setHotelRates = (hotelRates = [], callback) => {
      if (!isCurrentHotelParams(hotel)()) return
      dispatch(loadSuccess(actionTypes.FETCHING_HOTEL_SUCCESS, cloneDeep(hotelRates)));
      if (callback) callback();
    }
    const setLoading = (loading) => {
      if (!isCurrentHotelParams(hotel)()) return
      if (loading) {
        dispatch(startFetchingHotelAction())
      } else {
        dispatch(stopFetchingHotelAction())
      }
    }

    try {
      const endpoint = '/availability/hotels';
      const params = hotelServices.initHotelData(data);

      setParamsState(actionTypes.CHANGE_SEARCH_FLIGHT_PARAMS, flight)
      setParamsState(actionTypes.CHANGE_SEARCH_HOTEL_PARAMS, hotel)

      setHotelRates([]);
      setLoading(true);
      const response = await getHotelsAsync(endpoint, params, hotelRates, setHotelRates, isCurrentHotelParams(hotel));
      if (response.status === 200 && !response.errors) {
        if (response.data.isSuccess) {
          pushGtmDataLayerHotel({ hotels: response.data.hotelRates, params: data }, 'productImpressions');
          setHotelRates(response.data.hotelRates)
        } else {
          dispatch(loadFail(response.data.errors[0]))
        }
      } else {
        dispatch(processFailWithEnumType(response, 'data.errors[0].code', enumType.apiErrorCode.PROMOTION_INVALID));
      }
    } catch (errors) {
      // fail
      console.warn('getHotels ERROR', errors)
      dispatch(loadFail(null))
    }
    setLoading(false)
  }
}

const getRooms = (data) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHotelRoomAction())
    try {
      const response = await axiosProviderHotel.post(`/availability/rooms`, data)

      if (response.status === 200 && !response.errors) {
        if (response.data.isSuccess) {

          pushGtmDataLayerHotel({ hotels: [response.data], params: data }, 'productDetail', 'hotel', 'detail');
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_ROOM_SUCCESS,
              response.data
            )
          )
        } else {
          dispatch(loadFail(null))
        }
      } else {
        dispatch(loadFail(null))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingHotelRoomAction())
  }
}

const getRoom = (data) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHotelAction())
    try {
      const response = await axiosProviderHotel.post(`/availability/roomdetails`, data)

      if (response.status === 200 && !response.errors) {

        if (response.data.isSuccess) {
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_ROOM_DETAIL_SUCCESS,
              response.data.data
            )
          )
        } else {
          dispatch(loadFail(response.data.errors[0]))
        }

      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingHotelAction())
  }
}

const bookingHotel = (data) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProviderHotel.post(`/booking`, data)

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(actionTypes.BOOKING_HOTEL_SUCCESS, response.data.data)
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingAction())
  }
}

const commitBookingHotel = () => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProvider.post(`/availability/roomdetails`)

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(actionTypes.COMMIT_HOTEL_SUCCESS, {
            airports: response.data.data,
            type
          })
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingAction())
  }
}

const getHotels3s = (data, intl, isAllHotels) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHotelAction())

    dispatch(setSearchHotelParams(data))
    dispatch(loadSuccess(
      actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR,
      undefined
    ))
    try {
      const response = await axiosProviderHotel.get(`/availability/hotels?${queryString.stringify(data)}`)
      // const response = mockHotelListing;
      if (response.status === 200 && !response.errors) {
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_HOTEL_SUCCESS,
              {hotels: response.data.hotelRates, params: data} 
            )
          )
          pushGtmDataLayerHotel({ hotels: response.data.hotelRates, params: data }, 'productImpressions');
          if(response.data.hotelRates.length === 0) {
            message.warning(intl.formatHTMLMessage({
              id: 'Combo.Hotel.WarningMesage',
              defaultMessage: 'Không tìm thấy khách sạn phù hợp. Rất tiếc, hiện không có khách sạn nào phù hợp với bộ lọc hiện tại. Vui lòng tìm kiếm lại.'
            }))
          }
      } else {
        dispatch(processFailWithEnumType(response, 'data.errors[0].code', enumType.apiErrorCode.PROMOTION_INVALID));
      }
    } catch (errors) {
      if (errors?.response?.status !== 404) {
        dispatch(processFailWithEnumType(errors.response, 'data.errors[0].code', enumType.apiErrorCode.PROMOTION_INVALID, { promotionCode: data.promotionCode || ''}));
      }
    }
    dispatch(stopFetchingHotelAction())
  }
}

const getDetailHotel3s = (data, callBackFn) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingHotelRoomAction())
    dispatch(setSearchHotelParams(data))
    dispatch({
      type: actionTypes.RESET_FETCHING_HOTEL_ROOM
    })
    try {
      const response = await axiosProviderHotel.get(`/availability/rooms?${queryString.stringify(data)}`)
      if (response.status === 200 && !response.errors) {
        // response.data = mockAvailabilityRooms;
        if (response.data.isSuccess) {
          pushGtmDataLayerHotel({ hotels: [response.data], params: data }, 'productDetail', 'hotel', 'detail');
          if(callBackFn){
            callBackFn(response.data);
          }
          dispatch(
            loadSuccess(
              actionTypes.FETCHING_ROOM_SUCCESS,
              {rooms: response.data, params: data}
            )
          )
        } else {
          dispatch(loadFail(response.data.errors[0]))
          if(callBackFn){
            callBackFn(null);
          }
        }
      } else {
        dispatch(processFailWithEnumType(response, 'data.errors[0].code', enumType.apiErrorCode.PROMOTION_INVALID));
        if(callBackFn){
          callBackFn(null);
        }
      }
    } catch (errors) {
      // fail
      dispatch(processFailWithEnumType(errors.response,'data.errors[0].code', enumType.apiErrorCode.PROMOTION_INVALID));
      if(callBackFn){
        callBackFn(null);
      }
    }
    dispatch(stopFetchingHotelRoomAction())
  }
}

const getHotelFilter = (data) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingTagHotel())

    dispatch(setSearchHotelParams(data))
    try {
      const params = {
        languageCode: "vi-VN",
        productType: 2,
        type: 1
      }
      const response = await axiosProviderCms.post(`/frontend/taggroup`, params)
      // const response = mockHotelTaggroup;
      if (response.status === 200 && !response.errors) {
        
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_TAG_HOTEL_SUCCESS,
            response.data.data
          )
        )
      } else {
        dispatch(loadFail(null))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingTagHotel())
  }
}

const getParamsHotel3s = (hotelCode, anotherParams) => {
  return async (dispatch, getState) => {
    try {
      const response = await axiosProviderHotel.get(`/availability/get-hotel-info?code=${hotelCode}`)

      if (response.status === 200 && !response.errors) {
        if (response.data.isSuccess) {
          const data = hotelUtils.initParamsShortLink(response.data.hotel)
          const params = {
            ...data,
            isBestPrice: true,
            isReRender: true,
          }
          const paramsHotel = queryStringExtensions.getComboQueryStringWithHotel(routes.HOTEL, data.hotelId, params, '', anotherParams)
          const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
          const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
          Router.push(convertLinkLocale(language.data, paramsHotel, currency.data), undefined, { shallow: false })
          // dispatch(
          //   loadSuccess(
          //     actionTypes.FETCHING_PARAMS_HOTEL,
          //     response.data.hotel
          //   )
          // )
        } else {
          dispatch(loadFail(null))
        }
      } else {
        dispatch(loadFail(null))
      }
    } catch (errors) {
      dispatch(loadFail(null))
      dispatch(stopFetchingHotelRoomAction())
    }
  }
}

const PHSOrderHotel = (data, executeRecaptcha, paramsRedirect) => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        loadSuccess(actionTypes.FETCHING_PHS_ORDER_SUCCESS, true)
      )
      let captchaResult
      if (RECAPTCHA_KEY) {
        if (!executeRecaptcha) {
          return
        }
        captchaResult = await executeRecaptcha('home')
      }
      const response = await axiosProviderHotel.post(`/api/PhsOrder`, {
        ...data,
        CaptchaToken: captchaResult
      })
      dispatch(
        loadSuccess(actionTypes.FETCHING_PHS_ORDER_SUCCESS, false)
        )
      if (response.status === 200 && !response.errors) {
        if(paramsRedirect && data.bookingData.isBundle){
          SessionStorage.set(LOCAL_STORAGE_KEY.PHS_ORDER_BUNDLE, response.data)
        }else{
          SessionStorage.set(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL, response.data)
        }
        dispatch(
          loadSuccess(actionTypes.PHS_ORDER_HOTEL_SUCCESS, response.data)
        )
        const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
        const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
        let paramsRedirectGeneral = '';
        if(paramsRedirect && data.bookingData.isBundle){
          paramsRedirectGeneral = paramsRedirect
        }else{
          paramsRedirectGeneral = '/hotel/booking'
        }
        Router.push(convertLinkLocale(language.data, paramsRedirectGeneral, currency.data), undefined, { shallow: false })
      } else {
        const errorGet = get(errors, 'response.data.errors[0].code');
        if(errorGet === enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST || 
          errorGet === enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST
        ){
          dispatch(loadSuccess(
            actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR,
            errors.response.data.errors[0]
          ))
        }else{
          dispatch(loadFail(response.errors[0].message))
        }
      }
    } catch (errors) {
      // fail
      dispatch(
        loadFail(
          errors.response,
          null,
        )
      )
    }
    dispatch(
      loadSuccess(actionTypes.FETCHING_PHS_ORDER_SUCCESS, false)
    )
  }
}

const changeHotelTypeStayFilter = (value) => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_FILTER_TYPE_STAY,
        value
      )
    )
    dispatch(stopFetchingAction())
  }
}

const changeHotelRestPlaceFilter = (value) => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_FILTER_PRIORITY_REST_PLACE,
        value
      )
    )
    dispatch(stopFetchingAction())
  }
}

const resetHotelFilter = () => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.RESET_HOTEL_FILTER
      )
    )
    dispatch(stopFetchingAction())
  }
}

const changeSortHotel = (sortDirection) => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_SORT_HOTEL,
        sortDirection
      )
    )
    dispatch(stopFetchingAction())
  }
}

const changeHotel = (hotel) => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_HOTEL_SUCCESS,
        hotel
      )
    )
    dispatch(stopFetchingAction())
  }
}

const removePaymentUrl = () => {
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.REMOVE_PAYMENT_URL_SUCCESS
      )
    )
  }
}

const setSearchHotelParams = (params) => {
  return async (dispatch) => {
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_SEARCH_HOTEL_PARAMS,
        params
      )
    )
  }
}

export const startFetchingTagHotel = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.START_FETCHING_TAG_HOTEL
    })
  }
}

export const stopFetchingTagHotel = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.STOP_FETCHING_TAG_HOTEL
    })
  }
}

const changeHotelFilter = (values) => {
  return dispatch => {
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_HOTEL_FILTER,
        values
      )
    )
    dispatch(stopFetchingAction())
  }
}

const processFailWithEnumType = (response, path, enumType, params = {}) => {
  const errorCode = get(response, path, enumType)
  return dispatch => {
    errorCode ? dispatch(loadFail(response, undefined, undefined, params))
      : (response && response.errors && response.errors.length > 0 && response.errors[0].message ? dispatch(loadFail(response.errors[0].message)) : dispatch(loadFail(null)))
  }
}

const getHotelMemberRate = (params, callBackFn) => {
  return async dispatch => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProviderHotel.post(`/availability/member-rate`, params)
      if (response.status === 200 && !response.errors) {
        if(callBackFn) callBackFn(response.data);
        dispatch(
          loadSuccess(
            actionTypes.HOTEL_MEMBER_RATE_SUCCESS,
            response.data
          )
        )
      } else {
        dispatch(loadFail(null))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingTagHotel())
  }
}

const getCrossSaleBookables = (params, callBackFn) => {
  return async dispatch => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProviderHotel.post(`/availability/add-on-service/cross-sale`, params)
      if (response.status === 200 && !response.errors) {
        if(callBackFn) callBackFn(response.data);
        dispatch(
          loadSuccess(
            actionTypes.HOTEL_CROSS_SALE_BOOKABLES_SUCCESS,
            response.data
          )
        )
      } else {
        dispatch(loadFail(null))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null))
    }
    dispatch(stopFetchingTagHotel())
  }
}

export const getListHotelDeals = () => {
  return async(dispatch) => {
    dispatch(startFetchingAction())
    let data = [];
    try{
      const response = await axiosProviderHotel.get(
        `/availability/hotels/insider/best-prices`,
      )
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_HOTELS_DEALS_SUCCESS,
            response.data.data
          )
        )
        data = response.data.data.result
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors)
    {
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
    return data;
  }
}

export default {
  getHotelLocations,
  getHotels,
  getHotelByLocations,
  getRooms,
  getRoom,
  bookingHotel,
  commitBookingHotel,
  changeHotelTypeStayFilter,
  changeHotelRestPlaceFilter,
  resetHotelFilter,
  changeSortHotel,
  changeHotel,
  removePaymentUrl,
  getHotels3s,
  getDetailHotel3s,
  getHotelFilter,
  changeHotelFilter,
  getParamsHotel3s,
  PHSOrderHotel,
  getHotelMemberRate,
  getCrossSaleBookables,
  getListHotelDeals,
}
