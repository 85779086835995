import { reducer as modal } from 'redux-modal'
import fetching from './fetching'
import flight from './flight'
import filter from './filter'
import hotel from './hotel'
import homestay from './homestay'
import auth from './auth'
import carts from './carts'
import payment from './payment'
import rating from './rating'
import search from './search'
import combo from './combo'
import order from './order'
import voucher from './voucher'
import listing from './listing'
import navigation from './navigation'
import banner from './banner'
import cart from './cart'
import siteInfo from './siteInfo'
import experience from './experience'
import tour from './tour'
import blog from './blog'
import tag from './tag'
import loyalty from './loyalty'
import promotion from './promotion'
import footer from './footer'
import currency from './currency'
import credit from './credit'
import vietjet from './vietjetair';

export default {
  fetching,
  flight,
  filter,
  hotel,
  homestay,
  auth,
  modal,
  carts,
  payment,
  rating,
  search,
  combo,
  navigation,
  order,
  voucher,
  banner,
  cart,
  siteInfo,
  experience,
  tour,
  blog,
  listing,
  tag,
  loyalty,
  promotion,
  footer,
  currency,
  credit,
  vietjet
}
